/*
 * 업무구분: 영업
 * 화 면 명: MSPFS316D
 * 화면설명: 실시간계좌등록/조회(RP828201S.xfdl:8282)
 * 작 성 일: 2023.01.16
 * 작 성 자: 이윤석
 */
<template>
  <ur-page-container class="fts pal0" :show-title="false" title="실시간계좌등록/조회" :topButton="true">
    <ur-box-container alignV="start" componentid="" direction="column" class="mt20">  
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 고객번호 </label>
                <mo-text-field 
                  ref="ed_cust_no"
                  class="mw150"
                  :class="lv_ed_cust_no.error ? 'error' : ''"                  
                  v-model="lv_ed_cust_no.value" 
                  maxlength="7"
                  type="number"
                  @keyup="fn_SearchValid($event)"
                />              
                <mo-button class="w61" @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field 
                  class="w130" 
                  v-model="lv_ed_cust_nm.value" 
                  disabled
                />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
                <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_CustRetAccnSearch()"> 조회 </mo-button>
                <!-- <mo-button @click="fn_ReportPrint()" primary> 전자문서 조회 </mo-button>  -->
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->    

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title flex-sb">
          <h2>은행계좌 조회/등록</h2>
          <em class="colorR fs12">※ 은행계좌 해지는 U포탈에서 하시기 바랍니다.</em>
        </div>
        <div class="wrap-table h-scroll">
          <table class="table row-type">
            <thead>
              <th>실시간계좌등록</th>
              <th>업무등록구분</th>
              <th>예금주명</th>
              <th>은행계좌</th>
              <th>은행명</th>
              <th>주민번호</th>
              <th>고객계좌관계</th>
              <th>등록채널</th>
              <th>처리일자</th>
              <th>처리자</th>
            </thead>
            <tbody>
              <tr v-for="(items, idx) in lv_accn_list" :key="idx">
                <td> <mo-button class="btn-input" @click="fn_rtRstSelected('1', idx)"> 등록 </mo-button> </td>
                <td> {{items.reg_gb}} </td>
                <td> {{items.bfcer_dpstr_nm}} </td>
                <td> {{fn_AccnNoMask(items.bfcer_bnk_accn_no_encr)}} </td>
                <td> {{items.bfcer_bnk_nm}} </td>
                <td> {{fn_displayJuminNo(items.dpstr_rlnm_cnf_no_encr)}} </td>
                <td> {{items.cust_rltn_cdnm}} </td>
                <td> {{items.trt_path_cdnm}} </td>
                <td> {{fn_displayDate(1,items.proc_dt)}} </td>
                <td> {{items.proc_empno}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2>수익증권 실시간계좌 등록조회/해지</h2>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <th>선택</th>
              <th>업무등록구분</th>
              <th>예금주명</th>
              <th>은행계좌</th>
              <th>은행명</th>
              <th>주민번호</th>
              <th>처리일자</th>
              <th>처리자</th>
              <th>유선여부</th>
            </thead>
            <tbody>
             <tr v-for="(items, idx) in lv_bece_list" :key="idx">
                <td> <mo-button class="btn-input" @click="fn_rtRstSelected('2', idx)"> 해지 </mo-button> </td>
                <td> {{items.bfcer_rtt_accn_reg_nm}} </td>
                <td> {{items.bfcer_dpstr_nm}} </td>
                <td> {{fn_AccnNoMask(items.bfcer_bnk_accn_no_encr)}} </td>
                <td> {{items.bfcer_bnk_nm}} </td>                
                <td> {{fn_displayJuminNo(items.dpstr_rlnm_cnf_no_encr)}} </td>
                <td> {{fn_displayDate(1,items.proc_dt)}} </td>
                <td> {{items.proc_empno}} </td>
                <td> {{items.tel_use_nm}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <!-- <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_ReportPrintConfirm"> 확인 </mo-button>
        </div>
      </ur-box-container> -->
    </ur-box-container>  
 
    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>
   

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)

import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS316D",
  screenId: "MSPFS316D",
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo,      
    'msp-fs-308p': MSPFS308P,    
  },
  
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1} // 부모 선택한 현재 탭 인덱스
  },//props

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {


      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      
      pAlertPopupObj: {},
      lv_commCode: {},
      pCustInfoObj: {},


      // 팝업 객체
      pPopup308Obj: {},

      // 검색조건
      lv_ed_cust_no: {},        // 고객번호
      lv_accn_state_cd: {},     // 계좌상태
      lv_ed_cust_nm: {},        // 고객명

      // 기본정보
      lv_accn_list: [],         // 계좌정보 list
      lv_selected_accn_list: [],         // 선택된 계좌정보 list
      lv_report_accn_data: {},  //insertRP828200_performInsert 호출후 리포트용 임시파라미터 저장
      lv_bece_list: [],         // 수익증권정보 list
      lv_selected_bece_list: [],         // 선택된 수익증권정보 list


      // 고객정보 펼치기/접기
      isActive: false,

      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),     
      
      tr_code:'RP8282',
      
      // Accordion Toggle
      isActive: false,

      // 보고서용
      frm_issnc_ymd : '' ,
      bfcer_busn_trt_id : 'CU1801',
      rprt_frm_no : '0055',  
      issnc_hstr_sno : '',
      rprt_aplc_obj_cntnt : '',
      lv_form_count: 0 // 폼 count
    }
  },

  created () {    
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.fsUtils.saveProcTyp(this) // 탭별 초기화
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
     slctTabIdx(){
      console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {



    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS316D] fn_Init...')

      this.lv_ed_cust_no          = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no')      // 고객번호
      this.lv_accn_state_cd       = FSCommUtil.gfn_dataSet(0, '1', false, '', 'accn_state_cd')  // 계좌상태
      this.lv_ed_cust_nm          = FSCommUtil.gfn_dataSet(1)                                   // 고객명

      this.lv_accn_list           = []    // 계좌정보 list
      this.lv_selected_accn_list           = []    // 계좌정보 list
      this.lv_bece_list           = []    // 수익증권 list
      this.lv_selected_bece_list           = []    // 수익증권 list

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()

    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_OpenPopup(type) {
      switch (type) {
        case 0:
          this.$refs.popup308.fn_Open()
          break          
        case 1:

          break
      }
    },


    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 0:
          console.log('고객정보 callback...')

          // 고객번호 매핑 후 고객정보조회
          this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({key: 'v'})
          break
        }
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive;
    },


    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = this.lv_ed_cust_no.value
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event) {
      console.log('fn_SearchValid.....' , event.key) 

      if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7 ) {
        this.lv_ed_cust_no.error = false
        
        if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return 

        } else {
          if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
            // 고객정보조회
            this.fn_CustInfo()
          }
        }
      } else if( event.key == 'Delete' || event.key == 'Backspace' ) {
        // 고객명 초기화
        this.lv_ed_cust_nm.value = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }
    },



     /******************************************************************************
       * Function명 : fn_CustRetAccnSearch, fn_CustRetAccnSearchResult, fn_CustRetAccnSearchClear
       * 설명       : 고객별보유계좌조회
       ******************************************************************************/
      fn_CustRetAccnSearch() {
        console.log('fn_CustRetAccnSearch.....')

        this.lv_ed_cust_no.error = false
        if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return          
        } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return
        }

        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS13S2'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570228_S'
        this.eaiCommObj.params = {
          bfcer_cust_no : this.lv_ed_cust_no.value,     // 수익증권고객번호
          proc_gb    : 'S1',                         // 프로세스구분코드
          media      : 'I',                          // 매체구분코드
          tr_code    : 'RP8282',                     // 거래코드

        }
        console.log('params ', this.eaiCommObj.params)
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustRetAccnSearchResult)
      },

      fn_CustRetAccnSearchResult(pResultData) {
        
        console.log('pResultData', pResultData)
        let t_data = pResultData.data

        this.lv_accn_list = t_data.rp828200_O_01VO
        this.lv_bece_list = t_data.rp828200_O_02VO

        //this.cnt1 = this.lv_accn_list.length
        //this.cnt2 = this.lv_bece_list.length

      },


      fn_CustRetAccnSearchClear() {
        // 검색 결과 초기화
        this.lv_accn_list           = []    // 계좌정보 list
        this.lv_bece_list           = []    // 계좌정보 list
      },
      

      /******************************************************************************
       * Function명 : fn_rtRstSelected
       * 설명       : 조회내역 선택
       ******************************************************************************/
      fn_rtRstSelected(type, selectedIdx){

        this.lv_selected_accn_list = [] 
        this.lv_selected_bece_list = []  
        if (type == "1") 
        {
          //div_middle_grd_rp828200_o_01vo_oncellclick
          this.lv_selected_accn_list = this.lv_accn_list[selectedIdx]
          let t_popupObj = {
            closeFunc: this.fn_Close,
            confirm: true,
            confirmFunc: this.fn_rtAcctRegist,
            content: '수익증권 실시간출금계좌로 등록하시겠습니까?'
          }
          this.fn_AlertPopup(0, t_popupObj)
          
        }else{
          //div_middle_grd_rp828200_o_02vo_oncellclick
          this.lv_selected_bece_list = this.lv_bece_list[selectedIdx]
          let t_popupObj = {
            closeFunc: this.fn_Close,
            confirm: true,
            confirmFunc: this.fn_rtAcctTerminate,
            content: '수익증권 실시간출금계좌로 해지하시겠습니까?'
          }
          this.fn_AlertPopup(0, t_popupObj)

        }


      },


      fn_Close(){
        console.log('닫기 close')
      },
      fn_rtAcctRegist(){
         console.log('등록 save')



        console.log(this.lv_selected_accn_list)

        console.log('등록 save'  , this.lv_selected_accn_list)
 
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS13S2'  insertRP828200_performInsert
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570229_S'
        this.eaiCommObj.params = {
           bfcer_cust_no           : this.lv_selected_accn_list.bfcer_cust_no
          ,bfcer_core_accn_id      : this.lv_selected_accn_list.bfcer_core_accn_id
          ,dpstr_rlnm_cnf_no_encr  : this.lv_selected_accn_list.dpstr_rlnm_cnf_no_encr
          ,bfcer_dpstr_nm          : this.lv_selected_accn_list.bfcer_dpstr_nm
          ,bfcer_bnk_accn_no_encr  : this.lv_selected_accn_list.bfcer_bnk_accn_no_encr
          ,bfcer_rtt_accn_reg_cd   : '00' //this.lv_selected_accn_list.bfcer_rtt_accn_reg_cd
          ,bfcer_bnk_nm            : this.lv_selected_accn_list.bfcer_bnk_nm
          ,bfcer_bnk_cd            : this.lv_selected_accn_list.bfcer_bnk_cd
          ,tel_use_yn              : 'N'
          ,proc_gb                 : 'I1'
          ,media                   : 'I'
          ,tr_code                 : this.tr_code

        }
        console.log('params ', this.eaiCommObj.params)
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtAcctRegisthResult)

      },

      fn_rtAcctRegisthResult(pResultData){
         console.log('등록 save')
        //insertRP828200_performInsert
         console.log('pResultData', pResultData)

          //정상등록되었습니다.
         let t_data = pResultData.data.ioformReportVO

        console.log('t_data ', t_data)
        if(t_data.length > 0 ) {
            this.lv_report_accn_data = t_data[0]  //리포트 출력을 위해 임시 데이터 저장
            let t_popupObj = {
              cancel: false,              
              confirm: true,
              confirmFunc: this.fn_CustRetAccnSearch,
              content: "정상등록되었습니다."
            }
            // this.fn_AlertPopup(0, t_popupObj)
            this.fn_Confirm ()
        }
      },


      fn_rtAcctTerminate(){
        console.log('해지 save')
        console.log(this.lv_selected_bece_list)

          //FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtAcctTerminateResult)

        if(this.lv_selected_bece_list != '01'){
          this.eaiCommObj.lv_vm = this
            // this.eaiCommObj.trnstId = 'txTSSFS13S2'  insertRP828200_performInsert
            this.eaiCommObj.auth = 'I'
            this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570230_S'
            this.eaiCommObj.params = {
              bfcer_cust_no         : this.lv_ed_cust_no.value
              ,bfcer_rtt_accn_sno   : this.lv_selected_bece_list.bfcer_rtt_accn_sno
              ,bfcer_bnk_cd         : this.lv_selected_bece_list.bfcer_bnk_cd
              ,tel_use_yn           : 'N'
              ,proc_gb              : 'I'
              ,media                : 'I'
              ,tr_code              : this.tr_code
  
            }
            console.log('params ', this.eaiCommObj.params)
  
            FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtAcctTerminateResult)
            
        }      
      },

      fn_rtAcctTerminateResult(pResultData){
          console.log('해지 callback ')
        //insertRP828200_performInsert
          console.log('pResultData', pResultData)
        let t_data = pResultData.data.ioformReportVO

        console.log('t_data ', t_data)
        if(t_data.length > 0 ) {
            this.lv_report_accn_data = t_data[0]  //리포트 출력을 위해 임시 데이터 저장
            let t_popupObj = {
              cancel: false,              
              confirm: true,
              confirmFunc: this.fn_CustRetAccnSearch,
              content: "정상처리되었습니다."
            }
            // this.fn_AlertPopup(0, t_popupObj)
            this.fn_Confirm ()
            
        }
      } ,
      
      fn_displayJuminNo(val){
        return FSCommUtil.gfn_jumin_mask(val)
      },
      fn_displayDate(type, val){
        if (!val) return ''
        return FSCommUtil.gfn_dateReplace(type, val)
      },

      // 확인 버튼 클릭
      fn_Confirm() {   
        let t_popupObj = {          
          confirm: true,
          content: ['정상 처리 되었습니다'],
          contentTitle: '알림',
          confirmFunc: this.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no: this.lv_ed_cust_no.value
          },
        }
        this.fn_AlertPopup(9, t_popupObj)     
        
      },
      // 확인 버튼 클릭 시 리포트 호출
      fn_ReportPrintConfirm() {
        // formList = [] - formList 없음
        // nextFunc = {} - 다음화면 없음
        // isPrint = true - 이탈 프로세스 이므로 사용 안함
        // pScrnId = '' - 화면 id 필요 없음, 기존 store 에 담긴 내용 사용
        // isExit = true - 이탈 프로세스 true
        FSInfoUtil.commReportInfo(this, [], function() {}, true, '', true)
      }, 
      
      
      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')        

        let formList = []

        // 커버발행 파라미터 저장
        this.$bizUtil.fsUtils.saveCvrPblParam(this, {bfcer_cust_no: this.lv_ed_cust_no.value})
        
        let FS000018 = {formId: 'FS000018', fund_nm: ''} // // as-is : RCO000055 , FS000018_실시간(Real-Time)계좌 등록.해지 신청서

        let searchData = {
          tr_code : 'CU1801',
          bfcer_cust_no : this.lv_ed_cust_no.value,	
          frm_issnc_ymd : this.getStore('fsStore').getters.getBasInfo.data.busyDate,
          bfcer_busn_trt_id : 'CU1801',
          rprt_frm_no : '0055',  
          issnc_hstr_sno : this.lv_report_accn_data.h_seq_no,        // this.ds_report01.getColumn(0, "h_seq_no")		
          rprt_aplc_obj_cntnt : this.lv_report_accn_data.rt_acct_seq_no,   // this.ds_report01.getColumn(0, "rt_acct_seq_no")	
        }
        
        FS000018.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCO070500_Info',
        }
        formList.push(FS000018)

        let t_scrn_id = this.$options.screenId + '_' + String(this.lv_form_count)
        this.lv_form_count ++        
        
        // formList = [] - formList 없음
        // nextFunc = {} - 다음화면 없음
        // isPrint = true - 이탈 프로세스 이므로 사용 안함
        // pScrnId = '' - 화면 id 필요 없음, 기존 store 에 담긴 내용 사용
        // isExit 이탈 프로세스
        FSInfoUtil.commReportInfo(this, formList, function() {}, true, t_scrn_id, false)

        //고객별 보유계좌 재조회
        this.fn_CustRetAccnSearch()
      },

      /******************************************************************************
       * Function명 : fn_AccnNoMask
       * 설명       : 계좌번호 마스킹
       ******************************************************************************/
      fn_AccnNoMask(accn_no) {
        if(accn_no.length >= 9){      // 연계계좌번호 9자리 이상인 경우
          accn_no = accn_no.substr(0,3) + '*****' + accn_no.substr(8)
        }
        if(accn_no.length < 9){       // 연계계좌번호 9자리 미만인 경우
          accn_no = accn_no.substr(0,3) + '*'.repeat(accn_no.length - 3)
        }
        return accn_no
      }
  }
}

</script>
<style scoped>
</style>