/* 
* 업무구분: 영업
* 화 면 명: MSPFS317D
* 화면설명: 실시간입금처리 (RP828202S.xfdl:8282)
* 작 성 일: 2023.03.30
* 작 성 자: 이윤석
* 수정일 : 2024-06-18 강현철  사유 : ASR240600395 고위험승인내역 화면 개선 요청
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="실시간입금처리" :topButton="true">
    <ur-box-container alignV="start" componentid="" direction="column">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left column w100p mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 계좌번호</label>

                <mo-decimal-field
                  numeric
                  ref="ed_accn_no"
                  class="mw150 input-account"
                  :class="lv_ed_accn_no.error ? 'error' : ''"
                  v-model="lv_ed_accn_no.value"
                  @keyup="fn_SearchValid($event)"
                  @paste="fn_SearchValid($event, 'P')"
                  mask="#######-###"
                />
                <mo-dropdown ref="ed_acct_gb" :items="lv_acct_list" v-model="lv_ed_acct_gb.value" class="mw200" placeholder=" " :disabled="lv_ed_acct_gb.disabled" />

                <mo-text-field class="w130" v-model="lv_ed_cust_nm.value" disabled />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 유선구분 </label>
                <!-- <mo-radio-wrapper 
                      :items="ars_gb" 
                      v-model="lv_ed_rdo_ars_gb.value" 
                      class="row"
                      :class="lv_ed_rdo_ars_gb.error ? 'error' : ''"
                    />             
                    -->
                <mo-radio v-model="lv_ed_rdo_ars_gb.value" class="flex-unset mw-auto mr14" value="N"> 일반 </mo-radio>
                <mo-radio v-model="lv_ed_rdo_ars_gb.value" class="flex-unset mw-auto" value="Y" disabled> 유선 </mo-radio>
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row align-start">
                <label class="emphasis"> 입금구분 </label>
                <div class="w492">
                  <mo-radio-wrapper :items="depo_gb" v-model="lv_ed_rdo_depo_gb.value" class="row" :class="lv_ed_rdo_depo_gb.error ? 'error' : ''" @input="fn_BuyAccnNoListValid" />
                  <p class="colorR fs12 mt-2">{{ stc_depoGbComment }}</p>
                </div>
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 대리인여부 </label>
                <mo-radio-wrapper :items="agnt_gb" v-model="lv_ed_rdo_agnt_gb.value" class="row" :class="lv_ed_rdo_agnt_gb.error ? 'error' : ''" />
              </div>
            </div>
            <div class="row w100p right">
              <div class="wrap-button row">
                <mo-button @click="fn_Init"> 초기화 </mo-button>
                <mo-button primary @click="fn_Search" :disabled="btn_sch_disabled"> 조회 </mo-button>
                <!-- <mo-button @click="fn_ReportPrint()" primary> 전자문서 조회 </mo-button> -->
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info ref="fsCustInfo" :propObj="pCustInfoObj" @fs-cust-name="fn_SetEdCustName" @fs-cust-info="fn_SetEdCustInfo"></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">매수정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p">
            <colgroup>
              <col width="20%" />
              <col width="80%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <span> 매수계좌번호({{ buyAcctCnt }}건) </span>
                </th>
                <td colspan="3" class="flex-sb align-center pr20">
                  <span class="row gap10">
                    <mo-dropdown
                      ref="ed_buy_acct_gb"
                      :items="lv_buy_acct_list"
                      :itemText="'text'"
                      :itemValue="'value'"
                      v-model="lv_ed_buy_acct_gb.value"
                      class="mw400"
                      placeholder=" "
                      :disabled="lv_ed_buy_acct_gb.disabled"
                    />
                  </span>

                  <!--  JIRA에서 삭제 요청(23.07.10)
                  <template v-if="lv_fnd_grd_cd == '1'">
                    <mo-badge class="badge-type impossible2" text="초고위험" />
                  </template>
                  <template v-if="lv_fnd_grd_cd == '2'">
                    <mo-badge class="badge-type impossible" text="고위험" />
                  </template>              
                  <template v-if="lv_fnd_grd_cd == '4'">
                    <mo-badge class="badge-type possible" text="중위험" />
                  </template>              
                  <template v-if="lv_fnd_grd_cd == '5'">
                    <mo-badge class="badge-type possible" text="저위험" />
                  </template>              

                  </span>  

                  <template v-if="lv_fnd_grd_cd === '' && this.lv_ed_buy_acct_gb.value !== ''">
                      <span class="colorD"><em>투자성향평가 미대상</em></span>
                  </template>

                  <template v-if="lv_fnd_grd_cd != ''">
                    <span class="row gap10"> 
                      <mo-checkbox v-model="chk_adj" @click="fn_adj" v-if="btn_visible_yn" class="checkBtn">적합성체크 </mo-checkbox>
                      <em class="mw40">{{text_message}}</em>
                    </span>
                  </template>
                  -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2>수익증권 RT출금계좌 등록보기</h2>
        </div>
        <div class="wrap-table h-scroll-250">
          <table class="table row-type">
            <thead>
              <th>선택</th>
              <th>업무등록구분</th>
              <th>예금주명</th>
              <th>은행계좌</th>
              <th>은행명</th>
              <th>주민번호</th>
              <th>처리일자</th>
              <th>처리자</th>
              <th>유선여부</th>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in lv_bece_list" :key="idx" :class="{ checked: String(checked) === String(idx + 1) }" @click="fn_rowSelected(idx)">
                <td>
                  <mo-checkbox :ref="'rtChk' + idx" :disabled="isGridClickDisabled === false" v-model="checked" @input="fn_chkSelected(idx)" @click="fn_chkSelected(idx)" :value="String(idx + 1)">
                  </mo-checkbox>
                </td>
                <td>{{ row.bfcer_rtt_accn_reg_nm }}</td>
                <td>{{ row.bfcer_dpstr_nm }}</td>
                <td>{{ fn_AccnNoMask(row.bfcer_bnk_accn_no_encr) }}</td>
                <td>{{ row.bfcer_bnk_nm }}</td>
                <td>{{ fn_displayJuminNo(row.dpstr_rlnm_cnf_no_encr) }}</td>
                <td>{{ fn_displayDate(1, row.proc_dt) }}</td>
                <td>{{ row.proc_empno }}</td>
                <td>{{ row.tel_use_nm }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">계좌정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span> 출금은행</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_bfcer_bnk_nm.value" disabled />
                  </div>
                </td>
                <th>
                  <span> 출금계좌번호</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_bfcer_bnk_accn_no_encr.value" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 예금주명</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_bfcer_dpstr_nm.value" disabled />
                  </div>
                </td>
                <th>
                  <span> 입금계좌주명</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_edt_recp_nm.value" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>출금동의</span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row flex-sb">
                    <span>
                      <mo-button v-if="isWithdrawalBtnVisible" @click="fn_dfra_cnsnt"> 확인 </mo-button>
                      <em class="colorR fs12 pl10">{{ withdrawalMsg }}</em>
                    </span>
                    <span> <mo-button v-if="isUnWithdrawalBtnVisible" @click="fn_dfra_canc"> 출금계좌선택해제 </mo-button></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">입금정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis">SMS발송여부</span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      ref="ed_sms_dspch_yn"
                      :items="sms_dspch_yn"
                      v-model="lv_ed_sms_dspch_yn.value"
                      class="row"
                      :class="lv_ed_sms_dspch_yn.error ? 'error' : ''"
                      :disabled="lv_ed_sms_dspch_yn.disabled"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis">이체요청금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      ref="ed_rtt_rctm_req_amt"
                      class="input-long"
                      :class="lv_ed_rtt_rctm_req_amt.error ? 'error' : ''"
                      v-model="lv_ed_rtt_rctm_req_amt.value"
                      :disabled="lv_ed_rtt_rctm_req_amt.disabled"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis">비밀번호</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!-- 비밀번호 -->
                    <m-trans-key-input
                      v-if="isMobile"
                      ref="ed_secure_password"
                      class="w80"
                      :class="lv_ed_secure_password.error ? 'error' : ''"
                      v-model.trim="lv_ed_secure_password.value"
                      type="numberMax4"
                      start="0"
                      end="-1"
                      dialog="Y"
                      :disabled="lv_ed_secure_password.disabled"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"
                    />

                    <mo-text-field
                      v-else
                      ref="ed_secure_password"
                      class="w80"
                      :class="lv_ed_secure_password.error ? 'error' : ''"
                      v-model="lv_ed_secure_password.value"
                      underline
                      maxlength="4"
                      placeholder="비밀번호"
                      type="password"
                      :disabled="lv_ed_secure_password.disabled"
                      password
                    />
                    <!-- 비밀번호 -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>브릿지(예수금) 잔액 입금전</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_mae_bf_paym_amt.value" :class="lv_ed_mae_bf_paym_amt.error ? 'error' : ''" :disabled="lv_ed_mae_bf_paym_amt.disabled" />
                  </div>
                </td>
                <th>
                  <span>브릿지(예수금) 잔액 입금후</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_ed_mae_af_paym_amt.value" :class="lv_ed_mae_af_paym_amt.error ? 'error' : ''" :disabled="lv_ed_mae_af_paym_amt.disabled" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" :disabled="btn_depoReq_disabled" @click="fn_depoReq"> 의뢰 </mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup317 -->
    <msp-fs-317p ref="popup317" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup317Obj"></msp-fs-317p>

    <!-- popup313 -->
    <msp-fs-313p ref="popup313" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup313Obj"></msp-fs-313p>

    <!-- popup315 -->
    <msp-fs-315p ref="popup315" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup315Obj"></msp-fs-315p>

    <!-- popup305 -->
    <msp-fs-305p ref="popup305" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup305Obj"></msp-fs-305p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSBizUtil from '~/src/ui/fs/comm/FSBizUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSAmlUtil from '~/src/ui/fs/comm/FSAmlUtil'
import FSMessageUtil from '@/ui/fs/comm/FSMessageUtil' // message util

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSDocForm from '@/ui/fs/comm_elst/FSDocFormUtil' // 전자서식 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)

import MSPFS313P from '~/src/ui/fs/MSPFS313P' // 업무대리인조회 팝업
import MSPFS317P from '~/src/ui/fs/MSPFS317P' // 이체내역확인
import MSPFS315P from '~/src/ui/fs/MSPFS315P' // 자금세탁(AML) 고객확인 재이행 팝업
import MSPFS305P from '~/src/ui/fs/MSPFS305P' // 고위험승인요청_P(개인/법인)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS317D',
  screenId: 'MSPFS317D',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo,
    'msp-fs-313p': MSPFS313P,
    'msp-fs-317p': MSPFS317P,
    'msp-fs-315p': MSPFS315P,
    'msp-fs-305p': MSPFS305P
  },

  /***********************************************************************************
   * 화면 UI Property 정의
   * - 파리미터 받는 변수 props 정의
   ***********************************************************************************/
  props: {
    slctTabIdx: { type: Number, default: 1 } // 부모 선택한 현재 탭 인덱스
  }, //props

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.fn_Init()
    this.$bizUtil.fsUtils.saveProcTyp(this) // 탭별 초기화
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      // 공통 객체
      pHeaderObj: {
        title: '실시간(RealTime) 입금관리)',
        step: ''
      },
      lv_commCode: {},
      pCustInfoObj: {},

      // 팝업 객체
      pAlertPopupObj: {},
      pPopup313Obj: {}, // 대리인여부 팝업 객체
      pPopup315Obj: {}, // 자금세탁(AML)고객확인재이행 팝업 객체
      pPopup317Obj: {}, // 이체내역확인 팝업 객체
      pPopup305Obj: {}, // 이체내역확인 팝업 객체

      lv_reObjRtn: {}, // AML실제 소유주여부 팝업 결과
      lv_reCall_yn: '', // 자금세탁(AML) 고객확인 재이행 여부

      gSup_id: '', //책임자승인사번
      gAdm_seq_no: '', //책임자승인번호
      aml_yn: 'Y', //?? 자금세탁 기본값 설정

      lv_ed_check: {},

      // 검색조건
      lv_ed_accn_no: { value: '' }, // 계좌번호

      lv_ed_bfcer_cust_no: {}, // 고객정보

      lv_ed_acct_gb: {}, // 계좌종류
      lv_ed_buy_acct_gb: {}, // 매수계좌종류
      lv_ed_cust_nm: {}, // 고객명
      lv_ed_secure_password: {}, // 비밀번호

      lv_ed_ivt_prpns_2y_exp_yn: {}, // 투자설문 2년경과여부
      lv_ed_expd_stnd_ymd: {}, // 투자설문 유효기간 시작일

      // 계좌정보
      lv_cust_info: {}, // 고객정보
      lv_acct_list: [], // 계좌조회목록
      lv_buy_acct_list: [], // 매수계좌조회목록
      lv_buy_acct_hidden_list: [], // 매수계좌 처리용 매개목록

      lv_cms_acct_list: [], // CMS(자동이체) 등록계좌 목록

      lv_bece_list: [], // 수익증권정보 list

      lv_ed_rdo_ars_gb: [], //유선구분
      lv_ed_rdo_agnt_gb: [], //대리인구분
      lv_ed_rdo_depo_gb: [], //입금구분

      lv_ed_sms_dspch_yn: [], //SMS발송여부

      // transaction code 고정
      tr_code: 'RP8282',
      fnd_adj_stat_cd: 'C', //펀드적합도여부 ( 'X':투자설문없음, 'E':2년경과, 'N':미체크, 'F': 부적합, 'S':적합, 'P':대상아님(통과), 'C':초기화 )
      text_message: '', // 적합성체크 우측 디스플레이 값
      btn_visible_yn: false, //AS-IS 적합성 버튼을 체크박스로 처리하기 위해 동일한 변수로 선언함..
      chk_adj: false, // 적합성 체크
      selectedRow: '', // 수익증권 RT출금계좌 선택 ROW
      lv_bece_selected_list: [], // 수익증권정보 선택 list
      buyAcctCnt: '0', // 매수계좌번호 건수
      checked: '', // 수익증권 tr출금계좌 선택 설정
      withdrawalMsg: '', //출금동의 메시지
      isWithdrawalBtnVisible: false, // 출금 동의 버튼
      isUnWithdrawalBtnVisible: false, // 출금 해지 버튼

      lv_ed_bfcer_bnk_nm: {}, //출금은행
      lv_ed_bfcer_bnk_accn_no_encr: {}, //출금계좌번호
      lv_ed_bfcer_dpstr_nm: {}, //예금주명
      lv_ed_edt_recp_nm: {}, //입금계좌주명

      lv_ed_rtt_rctm_req_amt: {}, // 이체요청금액
      lv_ed_secure_password: {}, // 비밀번호
      lv_ed_mae_bf_paym_amt: {}, // 브릿지(예수금) 잔액 입금전
      lv_ed_mae_af_paym_amt: {}, // 브릿지(예수금) 잔액 입금후

      // 고객정보 펼치기/접기
      isActive: false,

      btn_sch_disabled: true, //조회 버튼 활성화여부
      btn_depoReq_disabled: true, // 의뢰 버튼 활성화여부

      isGridClickDisabled: true, // Grid CheckBox 선택

      stc_depoGbComment: '', //입금구분 선택시 우측 문자열 표기

      fv_dfraYn: '', // 출금동의

      lv_fnd_grd_cd: '',

      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      lv_isClear: false, // 보안키패드 초기화
      lv_masked_val: '', // 마스크 변수 체크

      lv_formList: []
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    ars_gb() {
      // 유선구분
      let rtn = []
      rtn.push({ value: 'Y', text: '일반' })
      rtn.push({ value: 'N', text: '유선' })
      return rtn
    },
    depo_gb() {
      // 입금구분
      let rtn = []
      rtn.push({ value: '1', text: '브릿지(예수금)계좌수금' })
      // rtn.push({value: '2', text: '자동이체 미이체처리'});
      return rtn
    },
    agnt_gb() {
      // 대리인여부
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },
    sms_dspch_yn() {
      // 유선구분
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    slctTabIdx() {
      //console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },

    'lv_ed_buy_acct_gb.value': {
      handler(after, before) {
        if (this.slctTabIdx == 2 && after != '') {
          //console.log('******************');
          this.fn_SetFndRiskAdjProcStatDispSet(after)
        }
      },
      deep: true
    },

    'lv_ed_rdo_agnt_gb.value': {
      handler(after, before) {
        if (this.slctTabIdx == 2) {
          // 대리인 만기체크
          this.eaiCommObj.lv_vm = this
          this.fn_AgentCheck()
        }
      },
      deep: true
    },

    'lv_ed_ivt_prpns_2y_exp_yn.value': {
      handler(after, before) {
        if (after != undefined || after != '') {
          if (this.slctTabIdx == 2) {
            this.fn_InvestChk(after)
            //this.fn_BuyAccnNoListValid(after)
          }
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if (this.lv_masked_val.length >= 4) {
        this.fn_SearchList()
        this.lv_masked_val = ''
      }
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      //console.log('fn_SetCommCode...')

      let params = []
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      //console.log('[MSPFS317D] fn_Init...')

      this.lv_ed_check = FSCommUtil.gfn_dataSet(1)

      this.lv_reObjRtn = {} // AML실제 소유주여부 팝업 결과
      this.lv_reCall_yn = '' // 자금세탁(AML) 고객확인 재이행 여부
      this.gSup_id = '' //책임자승인사번
      this.gAdm_seq_no = '' //책임자승인번호

      this.popupObj317 = {}

      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no') // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb') // 계좌종류
      this.lv_ed_buy_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_buy_acct_gb') // 매수계좌종류
      this.lv_ed_bfcer_cust_no = FSCommUtil.gfn_dataSet(1) // 고객번호
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_ed_secure_password = FSCommUtil.gfn_dataSet(1) // PW

      this.lv_ed_ivt_prpns_2y_exp_yn = FSCommUtil.gfn_dataSet(1) // 투자설문 2년경과여부
      this.lv_ed_expd_stnd_ymd = FSCommUtil.gfn_dataSet(1) // 투자설문 유효기간 시작일

      this.lv_cust_info = {} // 고객정보
      this.lv_acct_list = [] // 계좌조회목록
      this.lv_buy_acct_list = [] // 매수계좌조회목록
      this.lv_buy_acct_hidden_list = [] // 매수계좌 처리 매개목록

      this.lv_cms_acct_list = [] // CMS(자동이체) 등록계좌 목록

      this.lv_bece_list = [] // 수익증권 list
      this.lv_bece_selected_list = [] // 수익증권 selected list

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()

      this.lv_ed_rdo_ars_gb = FSCommUtil.gfn_dataSet(0, 'N', false, '') // 유선구분 일반(N) / 유선(Y)
      this.lv_ed_rdo_agnt_gb = FSCommUtil.gfn_dataSet(0, 'N') // 대리인구분
      this.lv_ed_rdo_depo_gb = FSCommUtil.gfn_dataSet(0, '1') // 입금구분

      this.fv_trCode = 'RP8282'
      this.text_message = ''
      this.fnd_adj_stat_cd = FSCommUtil.gfn_dataSet(1)
      this.buyAcctCnt = ''
      this.checked = '' // 수익증권 tr출금계좌 선택 설정

      this.withdrawalMsg = ''

      this.isWithdrawalBtnVisible = false // 출금 동의 버튼
      this.isUnWithdrawalBtnVisible = false // 출금 해지 버튼

      this.lv_ed_bfcer_bnk_nm = FSCommUtil.gfn_dataSet(1)
      this.lv_ed_bfcer_bnk_accn_no_encr = FSCommUtil.gfn_dataSet(1)
      this.lv_ed_bfcer_dpstr_nm = FSCommUtil.gfn_dataSet(1)
      this.lv_ed_edt_recp_nm = FSCommUtil.gfn_dataSet(1)

      this.lv_ed_sms_dspch_yn = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_sms_dspch_yn') // SMS발송여부
      this.lv_ed_sms_dspch_yn.disabled = true

      this.lv_ed_rtt_rctm_req_amt = FSCommUtil.gfn_dataSet(1, '', true, '')
      //this.lv_ed_rtt_rctm_req_amt.disabled = true
      this.lv_ed_secure_password = FSCommUtil.gfn_dataSet(1, '', true, '')
      this.lv_ed_mae_bf_paym_amt = FSCommUtil.gfn_dataSet(1, '', true, '')
      this.lv_ed_mae_af_paym_amt = FSCommUtil.gfn_dataSet(1, '', true, '')

      this.btn_sch_disabled = false
      this.btn_depoReq_disabled = true

      this.stc_depoGbComment = ''

      this.fv_dfraYn = '' // 출금동의

      this.isGridClickDisabled = true // Grid CheckBox 선택

      // 보안키패드 초기화
      this.lv_isClear = !this.lv_isClear ? true : false
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      //console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()

      //console.log('고객정보 호출됨 ')

      //console.log('ivt_prpns_2y_exp_yn ', this.lv_ed_ivt_prpns_2y_exp_yn.value)
      //console.log('expd_stnd_ymd ', this.lv_ed_expd_stnd_ymd.value)
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val

      // 고객번호 설정 (*이탈 시 커버발행 설정 목적)
      // this.$bizUtil.fsUtils.setCustNoForCvrBpl(this, val, this.lv_ed_accn_no.value)
      // 고객용 정보조회
      this.$bizUtil.fsUtils.selCustInfo(this, { bfcer_cust_no: this.lv_ed_accn_no.value })
    },

    fn_SetEdCustInfo(custObject) {
      // console.log('custObject : ', custObject )
      // 고객no
      this.lv_cust_info = custObject
      // this.lv_ed_bfcer_cust_no.value       = custObject.bfcer_cust_no
      // this.lv_ed_ivt_prpns_2y_exp_yn.value = custObject.ivt_prpns_2y_exp_yn
      // this.lv_ed_expd_stnd_ymd.value       = custObject.expd_stnd_ymd
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 계좌목록조회
     ******************************************************************************/
    fn_AccnNoList(pAccnNo) {
      let t_data = { bfcer_cstgr_no: pAccnNo, input_check: 'A', bfcer_accn_stat_cd: 'Y', bridge_aply_cd: 'Y' }
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
    },

    async fn_AccnNoListResult(pResultData) {
      let t_data = pResultData.data.sy000000_O_00VO

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      this.lv_ed_acct_gb.disabled = true

      if (t_data.length > 0) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({ value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no })
        })

        //if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' ) {
        // 자금세탁방지 KYC RULE Chec
        console.log('자금세탁방지 ~~~')
        let t_accn_no = this.lv_ed_accn_no.value
        let bfcer_cust_no = String(t_accn_no).substr(0, 7)

        // aml 체크는 하되 지속시켜야함. return 임시 주석처리
        if ((await this.fn_after_check(t_accn_no)) === false) {
          return
        }

        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        this.lv_ed_acct_gb.disabled = false

        // 계좌정보조회 결과 초기화
        this.fn_CustAccnInfoClear()

        // 고객정보조회
        this.fn_CustInfo()

        //this.fn_divSearchAccnNoList_CallBack()
      } else {
        let t_popupObj = { content: '검색할 계좌가 없습니다.' }
        this.fn_AlertPopup(0, t_popupObj)

        this.fn_Init()
      }
    },

    /******************************************************************************
     * Function명 : fn_divSearchAccnNoList_CallBack
     * 설명       : 투자성향 2년경과 여부 확인
     ******************************************************************************/
    fn_divSearchAccnNoList_CallBack() {
      //console.log('ivt_prpns_2y_exp_yn ', this.lv_ed_ivt_prpns_2y_exp_yn.value)
      //console.log('expd_stnd_ymd ', this.lv_ed_expd_stnd_ymd.value)
    },

    /******************************************************************************
     * Function명 : fn_CustAccnInfoSearch, fn_CustAccnInfoSearchResult
     * 설명       : 고객별보유계좌정보조회
     ******************************************************************************/
    fn_CustAccnInfoSearch() {
      //console.log('fn_CustAccnInfoSearch.....')
    },

    fn_CustAccnInfoClear() {
      //console.log('fn_CustAccnInfoClear.....')
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    async fn_SearchValid(event, flag) {
      //console.log('fn_SearchValid.....')

      let t_accn_no = this.lv_ed_accn_no.value
      // if( flag == 'P' ) {
      //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
      // } else {
      //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
      //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)
      //   }
      // }
      if (FSCommUtil.gfn_length(t_accn_no) >= 10) {
        this.lv_ed_accn_no.error = false

        if (!FSCommUtil.gfn_isNum(t_accn_no)) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile) {
            // 계좌목록조회
            this.fn_AccnNoList(t_accn_no)

            if (FSCommUtil.gfn_length(this.lv_ed_rdo_depo_gb.value) > 0) {
              this.fn_BuyAccnNoListValid()
            }
            // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)
          }
        }
      } else if (t_accn_no.length > 10 || event.key == 'Delete' || event.key == 'Backspace') {
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

        // 계좌목록/고객명 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''

        this.lv_reObjRtn = {}
        this.lv_reCall_yn = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }
    },

    /******************************************************************************
       * Function명 : fn_after_check
       * type
          2: 자금세탁방지 (AML) KYC RULE Check
       ******************************************************************************/
    async fn_after_check(cust_no) {
      console.log('fn_after_check....', cust_no)

      let t_popupObj = {}
      this.aml_yn = 'Y'
      // 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
      let aml_check_data = await FSAmlUtil.gfn_aml_check(this, cust_no, 'RP8282', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)

      console.log('aml_check_data....', aml_check_data)

      if (aml_check_data != true) {
        this.aml_yn = 'N'
        return false
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_BuyAccnNoListValid() {
      //console.log('fn_BuyAccnNoListValid....., ' )

      let accnNo = this.lv_ed_accn_no.value.replace(/[^0-9,*]/g, '')

      //console.log('매수계좌번호용 조건  : ', FSCommUtil.gfn_length(this.lv_ed_accn_no.value))

      // console.log('매수계좌번호용 조건  : ', accnNo)

      if (FSCommUtil.gfn_length(accnNo) == 10) {
        if (!FSCommUtil.gfn_isNum(accnNo)) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else {
          // 매수계좌목록조회
          this.fn_BuyAccnNoList()
        }
      } else {
        if (FSCommUtil.gfn_length(accnNo) == 0) {
          FSCommUtil.gfn_validate(this, '계좌번호를 조회하시기 바랍니다.')
          //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_BuyAccnNoList
     * 설명       : //RT 출금 수익증권 정보 조회
     ******************************************************************************/

    fn_BuyAccnNoList() {
      //console.log('fn_BuyAccnNoList ' , this.lv_ed_accn_no.value)

      //console.log(this.lv_ed_accn_no.value.substr(0, 7))
      let accnNo = this.lv_ed_accn_no.value.replace(/[^0-9,*]/g, '')

      if (FSCommUtil.gfn_length(this.lv_ed_bfcer_cust_no.value) == 0) {
        this.lv_ed_bfcer_cust_no.value = accnNo.substr(0, 7)
      }

      //고객예수금및펀드계좌목록조회
      //this.lv_ed_bfcer_cust_no.value = this.lv_ed_accn_no.value.substr(0, 7)

      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP828200_performDepoBuyAcctLst
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570234_S'

      this.eaiCommObj.params = {
        proc_gb: 'SA',
        tr_code: this.tr_code,
        bfcer_cust_no: this.lv_ed_bfcer_cust_no.value,
        bfcer_accn_no: accnNo,
        media: 'I'
      }
      //console.log(this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_buyAccnNoListResult)
    },

    /******************************************************************************
     * Function명 : fn_buyAccnNoListResult
     * 설명       : //RT 출금 수익증권 정보 후처리
     ******************************************************************************/

    fn_buyAccnNoListResult(pResultData) {
      //lv_buy_acct_list
      let t_data = pResultData.data.rp828200_O_04VO
      //console.log(t_data.length)

      this.lv_buy_acct_list = []
      this.lv_buy_acct_hidden_list = []
      this.lv_ed_buy_acct_gb.disabled = true
      this.buyAcctCnt = t_data.length
      if (t_data.length > 0) {
        this.lv_buy_acct_hidden_list = t_data
        t_data.forEach((item, idx) => {
          this.lv_buy_acct_list.push({ value: item.bfcer_accn_no, text: item.bfcer_accn_nm, hiddenValue: item.bfcer_accn_no, fnd_abbr_nm: item.bfcer_fnd_abbr_nm, fnd_cd: item.bfcer_fnd_cd })
        })

        //this.lv_ed_buy_acct_gb.value = 0
        this.lv_ed_buy_acct_gb.disabled = false

        this.fn_rdoDepoChange()
      }
    },

    /******************************************************************************
     * Function명 : fn_rdoDepoChange
     * 설명       : //입금구분 이벤트 처리
     ******************************************************************************/

    fn_rdoDepoChange() {
      //console.log('입금구분 : ' ,this.lv_ed_rdo_depo_gb.value)

      if (this.lv_ed_rdo_depo_gb.value == '2') {
        this.stc_depoGbComment = 'CMS(자동이체)등록계좌로 자동매입까지 가능'
        this.fn_inquiryCmsAcctLst() //CMS계좌번호목록조회 // inquiryCmsAcctLst();
        this.fn_chkCmsAcctLst() //chkCmsAcctLst();
        if (this.lv_buy_acct_list.length == 0) {
          FSCommUtil.gfn_validate(this, '자동이체 대상이 아닙니다.\n예수금수금으로 처리하세요.')
          return
        }
      } else if (this.lv_ed_rdo_depo_gb.value == '1') {
        this.stc_depoGbComment = '예수금계좌로 입금 및 펀드지정시 자동매입가능'
      }
    },

    /******************************************************************************
     * Function명 : fn_SetFndRiskAdjProcStatDispSet
     * 설명       : //적합성 관련 정보 설정
     ******************************************************************************/

    fn_SetFndRiskAdjProcStatDispSet(selectedValue) {
      //console.log('fn_SetFndRiskAdjProcStatDispSet ' ,this.lv_buy_acct_hidden_list[selectedValue])
      if (selectedValue != undefined) {
        //this.selectedRow = selectedValue
        this.selectedRow = this.lv_buy_acct_hidden_list.filter((item) => item.bfcer_accn_no === this.lv_ed_buy_acct_gb.value)[0]
        let bfcer_fnd_cd = this.selectedRow.bfcer_fnd_cd
        //console.log('bfcer_fnd_cd : ' , bfcer_fnd_cd)
        // 펀드계좌
        if (bfcer_fnd_cd != '100000') {
          let fnd_grd_cd = this.selectedRow.fnd_grd_cd
          let fnd_grd_nm = this.selectedRow.fnd_grd_nm
          //console.log('fnd_grd_cd : ' , fnd_grd_cd)
          //console.log('fnd_grd_nm : ' , fnd_grd_nm)

          this.setFndRiskDispSet(fnd_grd_cd, fnd_grd_nm)
          this.setFndAdjProcStatDispSet('N')
        } else {
          this.setFndRiskDispSet('', '')
          this.setFndAdjProcStatDispSet('P')
        }
      } else {
        this.setFndRiskDispSet('', '')
        this.setFndAdjProcStatDispSet('P')
      }
      this.chk_adj = false
    },

    /******************************************************************************
     * Function명 : setFndRiskDispSet
     * 설명       : //적합성 좌측 위험정보 설정
     ******************************************************************************/

    setFndRiskDispSet(fnd_grd_cd, fnd_grd_nm) {
      this.lv_fnd_grd_cd = fnd_grd_cd
    },

    /******************************************************************************
     * Function명 : setFndAdjProcStatDispSet
     * 설명       : //적합성 우측 문자열 표기
     ******************************************************************************/

    setFndAdjProcStatDispSet(stat_cd) {
      if (FSCommUtil.gfn_length(this.lv_ed_buy_acct_gb.value) > 0) {
        this.btn_visible_yn = true
        this.fnd_adj_stat_cd = stat_cd
        if (this.fnd_adj_stat_cd != 'C') {
          if (this.fnd_adj_stat_cd == 'X') {
            this.text_message = '투자성향평가 없음'
          } else if (this.fnd_adj_stat_cd == 'E') {
            this.text_message = '투자성향평가 2년경과'
          } else if (this.fnd_adj_stat_cd == 'N') {
            this.text_message = '미체크'
          } else if (this.fnd_adj_stat_cd == 'F') {
            this.text_message = '부적합'
          } else if (this.fnd_adj_stat_cd == 'S') {
            this.text_message = '적합'
          } else if (this.fnd_adj_stat_cd == 'P') {
            this.text_message = '투자성향평가 미대상'
            this.btn_visible_yn = false
          }
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_adj
     * 설명       : //적합성 버튼 ::: 사용 안 함(23.07.20)
     ******************************************************************************/

    fn_adj() {
      if (this.chk_adj) {
        this.chk_adj = false
        this.fnd_adj_stat_cd = 'N'
        //this.setFndRiskDispSet('', '')
        this.setFndAdjProcStatDispSet('N')
      } else {
        this.chk_adj = true
        //console.log('적합성 체크')
        let buy_acct_info = this.lv_buy_acct_list.filter((item) => item.value === this.lv_ed_buy_acct_gb.value)
        let bfcer_accn_no
        //console.log('buy_acct_info ' , buy_acct_info)

        if (buy_acct_info.length > 0) {
          this.gfn_inquiryIvtPrpnsFndGrdAdjInfo(this.lv_ed_buy_acct_gb.value)
        } else {
          FSCommUtil.gfn_validate(this, '계좌번호를 조회하시기 바랍니다.')
          this.lv_ed_buy_acct_gb.error = true
          //this.$refs['ed_buy_acct_gb'].focus()
          return
        }
      }
    },

    /******************************************************************************
     * Function명 : gfn_inquiryIvtPrpnsFndGrdAdjInfo
     * 설명       : 고객평가 공통 합업 확인용 임시 로컬 적용
     ******************************************************************************/

    gfn_inquiryIvtPrpnsFndGrdAdjInfo(bfcer_accn_no) {
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP828200_performDepoBuyAcctLst
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570263_S'

      this.eaiCommObj.params = {
        proc_gb: 'S',
        bfcer_accn_no: bfcer_accn_no,
        pgm_clsf_id: 'SY000000'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /******************************************************************************
     * Function명 : gfn_inquiryIvtPrpnsFndGrdAdjInfo
     * 설명       : 고객평가 공통 합업 확인용 임시 로컬 적용 후처리
     ******************************************************************************/

    //투자성향 2년경과 여부 확인 및 펀드적합도 여부 조회 call back
    fn_callBack(pResultData) {
      //console.log(pResultData)

      let t_data = pResultData.data.sy000000_O_01VO

      //console.log(t_data[0].ivt_prpns_2y_exp_yn)
      //console.log(t_data[0].ivt_prpns_stnd_ymd)
      //console.log(t_data[0].fnd_adj_yn)

      let ivt_prpns_2y_exp_yn = t_data[0].ivt_prpns_2y_exp_yn
      let ivt_prpns_stnd_ymd = t_data[0].ivt_prpns_stnd_ymd
      let fnd_adj_yn = t_data[0].fnd_adj_yn

      if (ivt_prpns_2y_exp_yn == 'X') {
        this.setFndAdjProcStatDispSet('X') //투자성향분석 없음

        let t_popupObj = {
          cancel: true,
          confirm: true,
          confirmFunc: this.fn_goInvest(),
          content: '투자성향진단이 필요합니다.\n평가를 진행하시겠습니까?'
        }
        this.fn_AlertPopup(0, t_popupObj)
      } else if (ivt_prpns_2y_exp_yn == 'Y') {
        this.setFndAdjProcStatDispSet('E') //투자성향평가 : 2년경과

        let strNotice = ''
        strNotice += '투자성향결과('
        strNotice += ivt_prpns_stnd_ymd
        strNotice += ')가 2년초과 or 부적합상품입니다.\n투자성향진단 재평가를 진행하시겠습니까?'

        let t_popupObj = {
          cancel: true,
          confirm: true,
          confirmFunc: this.fn_goInvest,
          content: strNotice
        }

        this.fn_AlertPopup(0, t_popupObj)
      } else {
        // 펀드부적합.
        if (fnd_adj_yn == 'N') {
          this.setFndAdjProcStatDispSet('F')
          let strNotice = ''
          strNotice += '투자성향결과('
          strNotice += ivt_prpns_stnd_ymd
          strNotice += ')가 2년초과 or 부적합상품입니다.\n투자성향진단 재평가를 진행하시겠습니까?'

          let t_popupObj = {
            cancel: true,
            confirm: true,
            confirmFunc: this.fn_goInvest,
            content: strNotice
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
        // 펀드적합.
        else {
          this.setFndAdjProcStatDispSet('S')
          FSCommUtil.gfn_validate(this, '투자성향에 적합한 펀드입니다.')
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크fn_AgentChk
     ******************************************************************************/
    fn_AgentCheck() {
      // 초기화
      this.lv_ed_accn_no.error = false

      if (this.lv_ed_rdo_agnt_gb.value == 'Y') {
        if (FSCommUtil.gfn_length(this.lv_ed_accn_no.value) < 10) {
          FSCommUtil.gfn_validate(this, '그룹번호가 입력되지 않았습니다.')
          this.lv_ed_accn_no.error = true
          this.lv_ed_rdo_agnt_gb.value = ''
          this.$refs['ed_accn_no'].focus()
          return
        }

        let t_params = {
          // PO 호출 조회조건
          bfcer_cust_no: this.lv_ed_bfcer_cust_no.value,
          tr_code: 'CU101000',

          // 추가정보
          menu_id: 'MSPFS317D',
          btn_id: 'lv_ed_rdo_agnt_gb'
        }
        FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
      }
    },

    /******************************************************************************
     * Function명 : fn_SY100400_callBack
     * 설명       : 대리인 여부 후처리  (진행중)
     ******************************************************************************/

    fn_SY100400_callBack(pResultData) {
      //console.log(pResultData)
      //data.tfoc02095_O_VO

      let t_data = pResultData.data
      /*
        let lv_agnt_proc_gb = t_data.agnt_prov_gb

        lv_agnt_proc_gb  == 0 // 대리인 팝업 MSPFS313P
        lv_agnt_proc_gb  == 1 // 대리인 수정등록 MSPFS302M
        lv_agnt_proc_gb  == 9 // 대리인 수정등록 MSPFS302M

         
*/
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 검색
     ******************************************************************************/
    fn_Search() {
      //console.log('this.aml_yn : ' , this.aml_yn)
      // 해당 처리결과가 AS-IS 와 틀리게 나와 후속업무를 위해 임시 AML 체크 부분 임시로 주석처리함.
      if (this.aml_yn === 'N') {
        let t_popupObj = { content: 'AML 확인해주세요.' }
	      this.fn_AlertPopup(0, t_popupObj)
	      return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_accn_no.value) !== 10) {
        FSCommUtil.gfn_validate(this, '계좌번호를 조회하시기 바랍니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      }

      let accnNo = this.lv_ed_accn_no.value.replace(/[^0-9,*]/g, '')

      if (FSCommUtil.gfn_length(this.lv_ed_bfcer_cust_no.value) == 0) {
        this.lv_ed_bfcer_cust_no.value = accnNo.substr(0, 7)
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570235_S'

      this.eaiCommObj.params = {
        proc_gb: 'S2',
        tr_code: this.tr_code,
        bfcer_cust_no: this.lv_ed_bfcer_cust_no.value //'1001097'//
      }

      console.log('bfcer_cust_no : ', this.lv_ed_bfcer_cust_no.value)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult)
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 검색 결과 후처리
     ******************************************************************************/
    fn_SearchResult(pResultData) {
      //console.log(pResultData)
      let t_data = pResultData.data.rp828200_O_02VO
      //console.log('key ',t_data)

      // 계좌목록조회 초기화 및 폼목록 초기화
      this.lv_bece_list = []
      this.lv_formList = []

      if (t_data.length > 0) {
        this.lv_bece_list = pResultData.data.rp828200_O_02VO
      }
    },

    /******************************************************************************
     * Function명 : fn_chkSelected
     * 설명       : 임의의 체크박스 클릭시 RT 출금계좌 선택
     ******************************************************************************/
    fn_chkSelected(selectedIdx) {
      //console.log('checked : ' , this.checked)
      if (FSCommUtil.gfn_length(this.checked) != 0) {
        this.lv_bece_selected_list = this.lv_bece_list[selectedIdx]
        //console.log('selectedItem.bfcer_rtt_accn_sno : ' , this.lv_bece_selected_list.bfcer_rtt_accn_sno)

        this.lv_ed_bfcer_bnk_nm.value = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_nm)
        this.lv_ed_bfcer_bnk_accn_no_encr.value = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_accn_no_encr)
        this.lv_ed_bfcer_dpstr_nm.value = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_dpstr_nm)
        this.lv_ed_edt_recp_nm.value = FSCommUtil.gfn_trim(this.lv_ed_cust_nm.value)

        // 출금동의 버튼 visible true
        // 출금해지 버튼 visible false
        // 출금버튼 옆 문자열 처리
        this.isWithdrawalBtnVisible = true
        this.withdrawalMsg = '[출금동의 확인바랍니다.]'
      } else {
        this.lv_bece_selected_list = []
        this.lv_ed_bfcer_bnk_nm.value = ''
        this.lv_ed_bfcer_bnk_accn_no_encr.value = ''
        this.lv_ed_bfcer_dpstr_nm.value = ''
        this.lv_ed_edt_recp_nm.value = ''
        this.withdrawalMsg = ''
        this.isWithdrawalBtnVisible = false
      }
    },

    /******************************************************************************
     * Function명 : fn_rowSelected
     * 설명       : 임의의 ROW선택시 RT 출금계좌 선택
     ******************************************************************************/
    fn_rowSelected(idx) {
      this.$refs['rtChk' + idx][0].onClick()
    },

    /***************************************************************************************
     *	Function		: fn_dfra_canc
     *	Parameter		:
     *	Return			:
     *	Desc			: 출금동의 해제
     *****************************************************************************************/
    fn_dfra_canc() {
      this.fn_depositInputProc(2)
      this.fv_chkAcctSelYn = 'N'
      this.lv_bece_selected_list = []
      this.withdrawalMsg = ''
      this.isWithdrawalBtnVisible = false
      this.lv_ed_sms_dspch_yn = []

      this.lv_ed_bfcer_bnk_nm.value = '' //출금은행
      this.lv_ed_bfcer_bnk_accn_no_encr.value = '' //출금계좌번호
      this.lv_ed_bfcer_dpstr_nm.value = '' //예금주명
      this.lv_ed_edt_recp_nm.value = '' //입금계좌주명

      this.lv_ed_rtt_rctm_req_amt.value = '' // 이체요청금액
      this.lv_ed_secure_password.value = '' // 비밀번호
      this.lv_ed_mae_bf_paym_amt.value = '' // 브릿지(예수금) 잔액 입금전
      this.lv_ed_mae_af_paym_amt.value = '' // 브릿지(예수금) 잔액 입금후

      this.isGridClickDisabled = true
      this.checked = ''
    },

    /***************************************************************************************
     *	Function		: fn_dfra_cnsnt
     *	Parameter		:
     *	Return			:
     *	Desc			: 출금동의
     *****************************************************************************************/
    fn_dfra_cnsnt() {
      //console.log('유선 구분 : ', this.lv_ed_rdo_ars_gb.value)
      if (FSCommUtil.gfn_length(this.lv_ed_rdo_ars_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '유선구분을 선택하시기 바랍니다.')
        this.lv_ed_rdo_ars_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      //console.log('대리인여부 : ', this.lv_ed_rdo_agnt_gb.value)
      if (FSCommUtil.gfn_length(this.lv_ed_rdo_agnt_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '대리인여부를 선택하시기 바랍니다.')
        this.lv_ed_rdo_agnt_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      //console.log('입금구분 : ', this.lv_ed_rdo_depo_gb.value)
      if (FSCommUtil.gfn_length(this.lv_ed_rdo_depo_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '입금구분을 선택하시기 바랍니다.')
        this.lv_ed_rdo_depo_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      this.fn_rtDfraRgst(this.lv_bece_selected_list.bfcer_rtt_accn_sno)
    },

    /***************************************************************************************
     *	Function		: fn_rtDfraRgst
     *	Parameter		:
     *	Return			:
     *	Desc			: 출금동의등록
     *****************************************************************************************/
    fn_rtDfraRgst(bfcer_rtt_accn_sno) {
      this.withdrawalMsg = '[출금동의확인요청중...]'
      //console.log( ' bfcer_rtt_accn_sno ' , bfcer_rtt_accn_sno )
      //console.log( ' bfcer_rtlv_bece_selected_listt_accn_sno ' , this.lv_bece_selected_list )

      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // insertRP828200_performDfraMain
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570236_S'

      this.eaiCommObj.params = {
        proc_gb: 'DN',
        tr_code: this.tr_code,
        bfcer_cust_no: this.lv_ed_accn_no.value.substr(0, 7), //this.lv_ed_bfcer_cust_no.value
        bfcer_core_accn_id: FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_core_accn_id),
        payr_no: FSCommUtil.gfn_trim(this.lv_bece_selected_list.payr_no),
        dpstr_rlnm_cnf_no_encr: FSCommUtil.gfn_trim(this.lv_bece_selected_list.dpstr_rlnm_cnf_no_encr),
        bfcer_bnk_accn_no_encr: FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_accn_no_encr),
        bfcer_dpstr_nm: FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_dpstr_nm),
        bfcer_bnk_cd: FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_cd),
        tel_use_yn: this.lv_ed_rdo_ars_gb.value,
        bfcer_rtt_accn_sno: bfcer_rtt_accn_sno,
        secure_passv: this.lv_ed_secure_password.value !== undefined ? this.lv_ed_secure_password.value : '',
        secure_encsymm: '', // 암호화 하느 뭔가의 값이라는데
        media: 'I',
        ars_pswn_yn: '' //ARS유무선비밀번호
      }

      console.log('출금동의확인요청중 : ', this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_rtDfraRgstResult)
    },
    /***************************************************************************************
     *	Function		: fn_rtDfraRgstResult
     *	Parameter		:
     *	Return			:
     *	Desc			: 출금동의등록 후처리
     *****************************************************************************************/
    fn_rtDfraRgstResult(pResultData) {
      // 확인 버튼 클릭후 그리드 선택 못하게 처리 필요
      console.log('fn_rtDfraRgst ', pResultData)
      let t_data = pResultData.data.rp828200_O_06VO // 메시지 처리용
      let t_data_1 = pResultData.data.tfoc02933_O_VO // 메시지 처리용

      console.log('출금동의등록 t_data =========', t_data)

      console.log('출금동의등록 t_data_1  ========= ', t_data_1)

      if (t_data_1.length > 0) {
        let lv_msg_code = t_data_1[0].msg_code
        let lv_msg_cntnt = t_data_1[0].msg_cntnt
        //console.log('lv_msg_code ', lv_msg_code)
        //console.log('lv_msg_cntnt ', lv_msg_cntnt)

        if (lv_msg_code == '1') {
          //console.log('t_data.msg_cntnt ' ,lv_msg_cntnt)
          this.withdrawalMsg = '[출금동의 확인바랍니다.]'
          FSCommUtil.gfn_validate(this, lv_msg_cntnt)
          return
        }
      } else {
        this.withdrawalMsg = '[출금동의 확인바랍니다.]'
        FSCommUtil.gfn_validate(this, '출금동의 처리시 문제가 발생되었습니다.\n관리자에게 문의바랍니다.')
        return
      }

      let frst_dfra_cnsnt_yn = t_data_1[0].frst_dfra_cnsnt_yn // 최초출금동의여부
      let dfra_cnsnt_yn = t_data_1[0].dfra_cnsnt_yn // 출금등록여부
      let payr_no = t_data_1[0].payr_no // 납부자번호
      let dfra_cnsnt_cbl_use_yn = t_data_1[0].dfra_cnsnt_cbl_use_yn // 출금동의시 전화사용여부
      let bfcer_rtt_accn_sno = t_data_1[0].bfcer_rtt_accn_sno // 수익증권RT계좌일련번호
      let str_birth_yyyy = ''
      let txt_tel_use_yn = ''
      let frst_dfra_reg_yn = 'Y'
      //console.log('dfra_cnsnt_cbl_use_yn ', dfra_cnsnt_cbl_use_yn)

      this.fv_dfraYn = dfra_cnsnt_yn // 출금등록여부

      // 납부자번호 SET
      // this.ds_list02_01.setColumn(this.ds_list02_01.rowposition, "payr_no", payr_no); // 납부자번호 그리드셋팅
      // this.bfcer_core_accn_id = this.ds_dfra_rslt.getColumn(0, "bfcer_core_accn_id"); // 수익증권핵심계좌ID
      // this.payr_no            = payr_no;            // 납부자번호
      // this.bfcer_rtt_accn_sno = bfcer_rtt_accn_sno; // 수익증권RT계좌일련번호

      if (dfra_cnsnt_cbl_use_yn == 'Y') txt_tel_use_yn = '유선'
      else txt_tel_use_yn = '내점'

      if (frst_dfra_reg_yn == 'Y') {
        this.withdrawalMsg = '[최초 출금동의입니다.(구분:' + txt_tel_use_yn + '}]'

        if (dfra_cnsnt_cbl_use_yn == 'N') {
          //if ( !this.lv_isProcess ) {
          this.fn_Report()
          //}
        } else {
          let dpstr_rlnm_cnf_no_encr = FSCommUtil.gfn_trim(this.lv_bece_selected_list.dpstr_rlnm_cnf_no_encr)
          // 최초출금동의 안내를 위해 추가 셋팅.
          if (FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 6, 1) == '1' || FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 6, 1) == '2') {
            str_birth_yyyy = '19' + FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 0, 2)
          } else if (FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 6, 1) == '3' || FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 6, 1) == '4') {
            str_birth_yyyy = '20' + FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 0, 2)
          }

          let str_birth_mm = FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 2, 2)
          let str_birth_dd = FSCommUtil.gfn_substr(dpstr_rlnm_cnf_no_encr, 4, 2)

          //계좌정보에서 가져옴
          let str_bank_nm = this.lv_bece_selected_list.bfcer_bnk_nm // 수익증권은행명
          let str_bank_acct_no = this.lv_bece_selected_list.bfcer_bnk_accn_no_encr // 수익증권은행계좌번호암호화
          let str_acct_nm = this.lv_bece_selected_list.bfcer_dpstr_nm // 수익증권예금주명

          let strNotice = ''
          strNotice += '내방처리시 출금이체 신청서를 수령해야 합니다.\n'
          strNotice += '유선처리시 아래 스크립트에 따라 고객동의를 확보해주시기 바랍니다.\n'
          strNotice += '\n'
          strNotice += str_birth_yyyy + '년 ' + str_birth_mm + '월 ' + str_birth_dd + '일생 ' + str_acct_nm + '(님)께서\n'
          strNotice += '삼성생명의 수익증권 자동이체(또는 R/T)를 ' + str_bank_nm + '(은행)\n' + str_bank_acct_no + ' 본인의 계좌로 신청하였습니다.\n'
          strNotice += '신청 내용에 동의 하십니까?'

          let t_popupObj = {
            cancel: true,
            confirm: true,
            // confirmFunc: this.fn_depositInputProc(),
            content: strNotice
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
      } else if (frst_dfra_reg_yn == 'N') {
        this.withdrawalMsg = '[기등록 출금동의 입니다.(구분:' + txt_tel_use_yn + '}]'
      }

      this.fn_depositInputProc(1) //depositInputProc(1); // 입금처리부분 활성화
    },
    /***************************************************************************************
     *	Function		: fn_depositInputProc
     *	Parameter		:
     *	Return			:
     *	Desc			: 입금처리부분 설정.
     *****************************************************************************************/
    fn_depositInputProc(deposit_mode) {
      console.log('deposit_mode ', deposit_mode)

      console.log('lv_ed_rdo_ars_gb ', this.lv_ed_rdo_ars_gb.value)

      if (deposit_mode == 1 || deposit_mode == undefined) {
        //console.log('입금활성화')

        // 조회버튼 비활성화
        this.btn_sch_disabled = true
        // sms발송여부  활성화
        this.lv_ed_sms_dspch_yn.disabled = false
        // RT입금요청금액 활성화
        this.lv_ed_rtt_rctm_req_amt.disabled = false
        // 금액버튼 활성화

        // 비밀번호 체크에 대한 개발정책이 현재 없으므로 강제로 활성화처리토록하겠음.
        this.lv_ed_secure_password.disabled = false
        /*
           if(this.lv_ed_rdo_ars_gb.value == 'N'){
             this.lv_ed_secure_password.disabled = false
           }
          */
        this.isWithdrawalBtnVisible = false // 출금 동의 버튼
        this.isUnWithdrawalBtnVisible = true // 출금 해지 버튼

        this.btn_depoReq_disabled = false
        this.isGridClickDisabled = false // 그리드 클릭
      } else {
        // console.log('비입금활성화')
        // 조회버튼 비활성화
        this.btn_sch_disabled = false
        // sms발송여부  활성화
        this.lv_ed_sms_dspch_yn.disabled = true
        // RT입금요청금액 활성화
        this.lv_ed_rtt_rctm_req_amt.disabled = true
        // 금액버튼 활성화

        // 비밀번호 체크에 대한 개발정책이 현재 없으므로 강제로 비활성화처리토록하겠음.
        this.lv_ed_secure_password.disabled = true
        /*
           if(this.lv_ed_rdo_ars_gb.value == 'N'){
             this.lv_ed_secure_password.disabled = true
           }
          */
        this.isWithdrawalBtnVisible = true // 출금 동의 버튼
        this.isUnWithdrawalBtnVisible = false // 출금 해지 버튼

        this.btn_depoReq_disabled = true
        this.isGridClickDisabled = true // 그리드 클릭
      }
    },

    /***************************************************************************************
     *	Function		: fn_inquiryCmsAcctLst
     *	Parameter		:
     *	Return			:
     *	Desc			: CMS계좌를 조회합니다.
     *****************************************************************************************/
    fn_inquiryCmsAcctLst() {
      if (FSCommUtil.gfn_length(this.lv_ed_accn_no.value) == 0) {
        FSCommUtil.gfn_validate(this, '계좌번호를 조회하시기 바랍니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      }

      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP828200_performInquryCmsAcctLst
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570233_S'

      this.eaiCommObj.params = {
        proc_gb: 'S3',
        tr_code: this.tr_code,
        bfcer_accn_no: this.lv_ed_accn_no.value,
        media: 'I'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_inquiryCmsAcctLstResult)
    },

    /***************************************************************************************
     *	Function		: fn_inquiryCmsAcctLstResult
     *	Parameter		:
     *	Return			:
     *	Desc			: CMS계좌를 후처리.
     *****************************************************************************************/

    fn_inquiryCmsAcctLstResult(pResultData) {
      //console.log('fn_inquiryCmsAcctLstResult :' , pResultData)
      this.lv_cms_acct_list = pResultData.data.rp828200_O_03VO

      //console.log('this.lv_cms_acct_list :' , this.lv_cms_acct_list)
    },

    /***************************************************************************************
     *	Function		: fn_chkCmsAcctLst
     *	Parameter		:
     *	Return			:
     *	Desc			: CMS외에 계좌는 제거합니다.
     *****************************************************************************************/
    fn_chkCmsAcctLst() {
      //this.lv_cms_acct_list  //lv_buy_acct_list
      //console.log('this.lv_buy_acct_list :' , this.lv_buy_acct_list)
      //console.log('this.lv_buy_acct_hidden_list :' , this.lv_buy_acct_hidden_list)

      //this.lv_cms_acct_list.push(cms_acct0)

      if (this.lv_buy_acct_list != null && this.lv_cms_acct_list != null) {
        this.lv_cms_acct_list.forEach((cmselement) => {
          let cms_bfcer_accn_no = cmselement.bfcer_accn_no

          this.lv_buy_acct_hidden_list.forEach((buyelement, index) => {
            let buy_bfcer_accn_no = buyelement.bfcer_accn_no

            if (cms_bfcer_accn_no === buy_bfcer_accn_no) {
              //console.log(buy_bfcer_accn_no, ' 동일한 계좌번호 ', cms_bfcer_accn_no)
              this.lv_buy_acct_hidden_list.splice(index, 1)
              return
            }
          })
        })

        this.lv_buy_acct_list = []
        this.lv_buy_acct_hidden_list.forEach((item, idx) => {
          this.lv_buy_acct_list.push({ value: idx, text: item.bfcer_accn_nm, hiddenValue: item.bfcer_accn_no })
        })

        this.buyAcctCnt = this.lv_buy_acct_list.length
      }
    },

    /***************************************************************************************
     *	Function		: fn_depoReq
     *	Parameter		:
     *	Return			:
     *	Desc			: 의뢰버튼 실행.
     *****************************************************************************************/
    fn_depoReq() {
      if (FSCommUtil.gfn_length(this.lv_ed_accn_no.value) == 0) {
        FSCommUtil.gfn_validate(this, '계좌번호를 조회하시기 바랍니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_rdo_ars_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '유선구분을 선택하시기 바랍니다.')
        this.lv_ed_rdo_ars_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_rdo_agnt_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '대리인여부를 선택하시기 바랍니다.')
        this.lv_ed_rdo_agnt_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_rdo_depo_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '입금구분을 선택하시기 바랍니다.')
        this.lv_ed_rdo_depo_gb.error = true
        //this.$refs['ed_accn_no'].focus()
        return
      }

      //this.lv_bece_selected_list
      if (this.lv_bece_selected_list.length == 0) {
        FSCommUtil.gfn_validate(this, 'RT출금계좌를 선택하시기 바립니다.')

        return
      }

      /**출금 동의한 결과를 처리하기위해 관련 정보 처리 로직 필요함. */
      if (this.fv_dfraYn == 'N' || FSCommUtil.gfn_length(this.fv_dfraYn) == 0) {
        FSCommUtil.gfn_validate(this, '출금동의를 확인하시기 바랍니다.')

        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_buy_acct_gb.value) == 0) {
        FSCommUtil.gfn_validate(this, '매수계좌번호를 선택하시기 바랍니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_buy_acct_gb.error = true
        //this.$refs['ed_buy_acct_gb'].focus()
        return
      }

      /*   if(this.fnd_adj_stat_cd == 'X'){

          let strNotice = "투자성향분석이 필요합니다.\n평가를 진행하시겠습니까?"

          let t_popupObj = {
            cancel: true,              
            confirm: true,
            confirmFunc: this.fn_goInvest(),
            content: strNotice
          }
          this.fn_AlertPopup(0, t_popupObj)

        }

        // 적합성 체크 제거 (JIRA)
        if(this.fnd_adj_stat_cd == 'N'){
          //FSCommUtil.gfn_validate(this, '적합성체크를 실행하시기 바랍니다..')
          //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
          //this.lv_ed_buy_acct_gb.error = true              
          //this.$refs['ed_buy_acct_gb'].focus()
          //return
        }    

        // 2년경과 또는 부적합.
        if(this.fnd_adj_stat_cd == 'E' || this.fnd_adj_stat_cd == 'F'){

          let strNotice = ""
          strNotice += "투자성향진단 2년이 경과되었습니다.("
          strNotice += this.lv_ed_expd_stnd_ymd.value
          strNotice += ")\n가 2년초과 or 부적합상품입니다.\n투자성향진단 재평가를 진행하시겠습니까?"

          t_popupObj = {
              cancel: true,
              confirm: true,
              confirmFunc: this.fn_goInvest,
              content: strNotice,
            }          

          this.fn_AlertPopup(0, t_popupObj)

        } */

      if (FSCommUtil.gfn_length(this.lv_ed_sms_dspch_yn.value) == 0) {
        FSCommUtil.gfn_validate(this, 'SMS발송여부를 선택하시기 바랍니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_sms_dspch_yn.error = true
        //this.$refs['ed_sms_dspch_yn'].focus()
        return
      }

      if (FSCommUtil.gfn_length(this.lv_ed_rtt_rctm_req_amt.value) == 0) {
        FSCommUtil.gfn_validate(this, '이체요청금액을 입력하시기 바랍니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_rtt_rctm_req_amt.error = true
        this.$refs['ed_rtt_rctm_req_amt'].focus()
        return
      }

      if (this.lv_ed_rtt_rctm_req_amt.value < 10000) {
        FSCommUtil.gfn_validate(this, '이체요청금액은 10,000원 이상만 가능합니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_rtt_rctm_req_amt.error = true
        this.$refs['ed_rtt_rctm_req_amt'].focus()
        return
      }

      if (this.lv_ed_rtt_rctm_req_amt.value > 1000000000) {
        FSCommUtil.gfn_validate(this, '(개인)입금이체 1회최대한도는 10억입니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_rtt_rctm_req_amt.error = true
        this.$refs['ed_rtt_rctm_req_amt'].focus()
        return
      }

      if (this.isMobile && this.isMtrans) {
        if (FSCommUtil.gfn_length(this.lv_masked_val) != 4) {
          FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
          this.lv_ed_secure_password.error = true
          this.$refs['ed_secure_password'].focus()
          return
        }
      } else {
        if (FSCommUtil.gfn_length(this.lv_ed_secure_password.value) == 0) {
          FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
          this.lv_ed_secure_password.error = true
          this.$refs['ed_secure_password'].focus()
          return
        }
      }

      let telNo = FSCommUtil.gfn_isNull(this.lv_cust_info.bman_celph_dstno_encr)
        ? ''
        : this.lv_cust_info.bman_celph_dstno_encr + '-' + this.lv_cust_info.bman_celph_tlnum_no_encr + '-' + this.lv_cust_info.bman_celph_bkno_sno_encr

      if (this.lv_ed_sms_dspch_yn.value == 'Y' && FSCommUtil.gfn_isNull(telNo)) {
        FSCommUtil.gfn_validate(this, '핸드폰번호 미등록 고객으로 SMS발송이 불가능합니다.')
        //this.lv_ed_rdo_depo_gb              = FSCommUtil.gfn_dataSet(1, '')
        this.lv_ed_sms_dspch_yn.error = true
        //this.$refs['ed_sms_dspch_yn'].focus()
        return
      }

      // fn_depositCheck

      let buy_acct_info = this.lv_buy_acct_list.filter((item) => item.value === this.lv_ed_buy_acct_gb.value)
      let bfcer_accn_no
      console.log('buy_acct_info ', buy_acct_info)

      // if(buy_acct_info.length > 0) {
      //   //console.log('buy_acct_info . hiddenValue ' , buy_acct_info[0].hiddenValue)
      //   console.log('buy_acct_info ' , buy_acct_info[0].hiddenValue.substr(0,7))
      //   bfcer_accn_no = buy_acct_info[0].hiddenValue.substr(0,7)
      // }

      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP828200_performDpsEnableCfrmMain
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570237_S'

      this.eaiCommObj.params = {
        proc_gb: 'DB',
        tr_code: this.tr_code,
        bfcer_cust_no: this.lv_ed_bfcer_cust_no.value,
        rtt_rctm_req_amt: this.lv_ed_rtt_rctm_req_amt.value,
        //,bfcer_accn_no    : bfcer_accn_no
        bfcer_accn_no: this.lv_ed_buy_acct_gb.value,
        //,bfcer_bnk_accn_no_encr : this.lv_ed_buy_acct_gb.value
        ars_pswn_yn: '', //this.lv_rdo_ars_gb.value === 'N'? '' : 'Y'             // 일반(N)/유선(Y) 구분
        secure_passv: this.lv_ed_secure_password.value,
        secure_encsymm: 'SEED',
        media: 'I'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_depoReqResult)
    },

    /***************************************************************************************
     *	Function		: fn_depoReqResult
     *	Parameter		:
     *	Return			:
     *	Desc			: 의뢰버튼 후처리.
     *****************************************************************************************/
    fn_depoReqResult(pResultData) {
      // console.log('rp828200MsgYn_list =============== :' , pResultData.data.rp828200MsgYn_list)

      if (!FSCommUtil.gfn_isNull(pResultData.data) && pResultData.data.error_msg.length > 0) {
        if ('250510' === pResultData.data.error_msg) {
          //정상처리되었습니다.
          this.fn_ReportPrint()
          this.fn_callBtranPop()
        }
        //if ('250027' !== pResultData.data.error_msg) {
        else {
          let t_popupObj = { content: FSMessageUtil.commMessage([])[pResultData.data.error_msg] }
          this.fn_AlertPopup(0, t_popupObj)
        }
      }
    },

    /***************************************************************************************
     *	Function		: fn_goInvest
     *	Parameter		:
     *	Return			:
     *	Desc			: 고객평가 이동.
     *****************************************************************************************/
    fn_goInvest() {
      let params = { bfcer_cust_no: this.lv_ed_accn_no.value.substr(0, 7) }

      this.$router.push({ name: 'MSPFS110M', params: params })
    },

    /***************************************************************************************
     *	Function		: fn_InvestChk
     *	Parameter		:
     *	Return			:
     *	Desc			: 고객평가 체크.
     *****************************************************************************************/
    fn_InvestChk() {
      let t_popupObj = {}

      //console.log('fn_InvestChk')

      //console.log('lv_ed_ivt_prpns_2y_exp_yn ', this.lv_ed_ivt_prpns_2y_exp_yn.value)

      let strNotice = ''
      if (this.lv_ed_ivt_prpns_2y_exp_yn.value == 'X') {
        this.setFndAdjProcStatDispSet('X')
        t_popupObj = {
          cancel: true,
          confirm: true,
          confirmFunc: this.fn_goInvest,
          content: '투자성향분석이 필요합니다.\n평가를 진행하시겠습니까?'
        }

        this.fn_AlertPopup(0, t_popupObj)
      } else if (this.lv_ed_ivt_prpns_2y_exp_yn.value == 'Y') {
        this.setFndAdjProcStatDispSet('E')
        let strNotice = ''
        strNotice += '투자성향진단 2년이 경과되었습니다.('
        strNotice += this.lv_ed_expd_stnd_ymd.value
        strNotice += ')\n재평가를 진행하시겠습니까?'

        t_popupObj = {
          cancel: true,
          confirm: true,
          confirmFunc: this.fn_goInvest,
          content: strNotice
        }

        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /***************************************************************************************
     *	Function		: fn_depositCheck
     *	Parameter		:
     *	Return			:
     *	Desc			: 입금가능여부 체크.
     *****************************************************************************************/
    fn_callBtranPop() {
      console.log('lv_buy_acct_list ', this.lv_buy_acct_list)
      console.log('lv_buy_acct_hidden_list ', this.lv_buy_acct_hidden_list)

      let buy_acct_info = this.lv_buy_acct_list.filter((item) => item.value === this.lv_ed_buy_acct_gb.value)
      //매수계좌번호 선택된 값의 7자리(수익증권고객번호)
      // let buy_acct_info  = this.lv_buy_acct_hidden_list.filter(item => item.bfcer_accn_no === this.lv_ed_buy_acct_gb.value)
      let bfcer_accn_no
      let bfcer_cust_no
      let bfcer_fnd_abbr_nm
      let bfcer_fnd_cd
      console.log('fn_callBtranPop buy_acct_info ', buy_acct_info)

      // if(buy_acct_info.length > 0) {
      bfcer_accn_no = buy_acct_info[0].hiddenValue
      bfcer_cust_no = buy_acct_info[0].hiddenValue.substr(0, 7)
      bfcer_fnd_abbr_nm = buy_acct_info[0].fnd_abbr_nm
      bfcer_fnd_cd = buy_acct_info[0].fnd_cd
      //console.log('lv_ed_buy_acct_gb ' , this.lv_ed_buy_acct_gb.value)
      // }

      let lv_Vm = this
      lv_Vm.popupObj317.bfcer_cust_no = bfcer_cust_no // 수익증권고객번호
      lv_Vm.popupObj317.rtt_rctm_sc_cd = this.lv_ed_rdo_depo_gb.value // 입금구분
      lv_Vm.popupObj317.bfcer_rtt_accn_sno = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_rtt_accn_sno) // 수익증권RT계좌일련번호
      lv_Vm.popupObj317.bfcer_bnk_cd = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_cd) // 수익증권은행코드
      lv_Vm.popupObj317.bfcer_bnk_nm = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_nm) // 수익증권은행명
      lv_Vm.popupObj317.bfcer_bnk_accn_no_encr = this.fn_AccnNoMask(FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_bnk_accn_no_encr)) // 수익증권은행계좌번호암호화
      lv_Vm.popupObj317.bfcer_dpstr_nm = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_dpstr_nm) // 수익증권예금주명
      lv_Vm.popupObj317.dpstr_rlnm_cnf_no_encr = FSCommUtil.gfn_trim(this.lv_bece_selected_list.dpstr_rlnm_cnf_no_encr) // 예금주실명확인번호암호화
      lv_Vm.popupObj317.rtt_rctm_req_amt = this.lv_ed_rtt_rctm_req_amt.value // RT입금요청금액

      lv_Vm.popupObj317.tel_use_yn = this.lv_ed_rdo_ars_gb.value // 유선구분등록여부
      lv_Vm.popupObj317.sms_dspch_yn = this.lv_ed_sms_dspch_yn.value // SMS발송여부
      lv_Vm.popupObj317.rdo_depo_gb = this.lv_ed_rdo_depo_gb.value // 입금구분
      lv_Vm.popupObj317.bfcer_accn_no = bfcer_accn_no // 수익증권계좌번호
      lv_Vm.popupObj317.payr_no = FSCommUtil.gfn_trim(this.lv_bece_selected_list.payr_no) // 납부자번호
      lv_Vm.popupObj317.bfcer_core_accn_id = FSCommUtil.gfn_trim(this.lv_bece_selected_list.bfcer_core_accn_id) // 수익증권핵심계좌ID
      lv_Vm.popupObj317.secure_password = this.lv_ed_secure_password.value // 비밀번호
      lv_Vm.popupObj317.secure_encsymm = 'SEED' // ENCSYMM
      lv_Vm.popupObj317.ars_passv_yn = 'N' // ARS 비밀번호

      lv_Vm.popupObj317.bfcer_fnd_abbr_nm = bfcer_fnd_abbr_nm // 수익증권펀드약어명
      lv_Vm.popupObj317.bfcer_fnd_cd = bfcer_fnd_cd // 수익증권펀드코드
      lv_Vm.popupObj317.fnd_cust_nm = this.lv_ed_cust_nm.value // 고객명

      this.fn_OpenPopup('MSPFS317P', lv_Vm.popupObj317)
    },

    /**************************************************************************************************************
     * 펀드등급, 펀드적합성 화면메시지 Clear
     *  @param
     *  @return
     ***************************************************************************************************************/
    fn_cleanRiskFndAdjDisp() {
      this.setFndRiskDispSet('', '') //펀드위험등급 Clear
      this.setFndAdjProcStatDispSet('C') //펀드적합버튼/상태 Clear
    },

    /******************************************************************************
     * Function명 : fn_callBack317
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) :
     ******************************************************************************/
    fn_callBack317(rtnData) {
      if (rtnData == 'Y') {
        let lv_text = '같은 그룹번호에서 계속 출금처리하시겠습니까?'
        this.fn_AlertPopup(0, { content: lv_text, confirm: true, confirmFunc: this.fn_dfra_canc, closeFunc: '' })
      } else {
        let lv_text = '화면 초기화 하시겠습니까?'
        this.fn_AlertPopup(0, { content: lv_text, confirm: true, confirmFunc: this.fn_init, closeFunc: '' })
      }
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type)
          MSPFS313P: 업무대리인조회 팝업
          MSPFS317P: 이체내역확인
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
       ******************************************************************************/
    fn_OpenPopup(type, pData) {
      switch (type) {
        case 'MSPFS317P':
          // 이체내역확인 팝업
          this.pPopup317Obj = pData
          this.$refs.popup317.fn_Open()
          break
        case 'MSPFS313P':
          // 업무대리인 조회 팝업
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break

        case 'MSPFS315P':
          // 자금세탁(AML) 고객확인 재이행
          this.pPopup315Obj = pData
          this.$refs.popup315.fn_Open()
          break

        case 'MSPFS305P':
          // 자금세탁(AML) 고객확인 재이행
          this.pPopup305Obj = pData
          this.$refs.popup305.fn_Open()
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) 
          0: 고객정보 
          1: 펀드정보 
          MSPFS105P: 상담텔러 팝업
          MSPFS301P: AML_실제소유자여부확인
          MSPFS303P: FATCA개인
          MSPFS305P: 고위험승인요청
          MSPFS309P: 세제상품전금융기관등록조회
          MSPFS313P: 업무대리인 조회
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
          MSPFS317P: 이체내역확인
          MSPFS321P: 직원검색 팝업
          MSPFS322P: 지역단FP 팝업
          MSPFS323P: FO/FP직원 팝업
       ******************************************************************************/
    fn_Popup_CallBack(type, pData, pSubId) {
      switch (type) {
        case 0:
          //console.log('고객정보 callback...')

          // 고객번호 매핑 후 조회
          //this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({ key: 'v' }, 0)
          break

        case 1:

        case 'MSPFS301P':
          //console.log('AML실제소유자여부 callback...')

          // 고객번호 매핑 후 조회
          this.lv_reObjRtn = pData
          console.log(this.lv_reObjRtn)

          //this.lv_ed_cust_no.value = pData.cust_no
          //this.fn_SearchValid({key: 'v'}, 0)
          break

        case 'MSPFS305P':
          console.log('고위험승인요청 callback...')

          // 고객번호 매핑 후 조회
          // ASR240600395 고위험승인내역 화면 개선 요청 수정
          this.lv_ed_accn_no.value = pData.acct_no
          this.fn_SearchValid({ key: 'v' })
          break

        case 'MSPFS315P':
          console.log('자금세탁(AML) 고객확인 재이행 callback...')

          // 고객번호 매핑 후 조회
          this.lv_reObjRtn = pData
          console.log(this.lv_reObjRtn)

          this.lv_ed_accn_no.value = pData.acct_no
          this.lv_reCall_yn = 'Y'

          this.fn_SearchValid({ key: 'v' })
          break
        // case 'MSPFS317P':
        //   console.log('이체내역확인 callback...')

        //   this.fn_ReportPrint()
        //   break
      }
    },

    fn_displayJuminNo(val) {
      return FSCommUtil.gfn_jumin_mask(val)
    },

    fn_displayDate(type, val) {
      if (!val) return ''
      return FSCommUtil.gfn_dateReplace(type, val)
    },

    /******************************************************************************
     * Function명 : fn_Report
     * 설명       : 전자서식 생성
     ******************************************************************************/
    async fn_Report() {
      console.log('fn_Report....')

      let formList = []

      // 커버발행 파라미터 저장
      this.$bizUtil.fsUtils.saveCvrPblParam(this, { bfcer_cust_no: this.lv_cust_no })

      let FS000024 = { formId: 'FS000024', isMulti: true } // // as-is : RCO000101 , FS000024_개인(신용)정보 등의 처리 동의 및 출금이체 신청서
      let busyDate = FSCommUtil.gfn_strToday()

      let searchData = {
        btrans_bank_nm: this.lv_ed_bfcer_bnk_nm.value,
        btrans_acct_no: this.lv_ed_bfcer_bnk_accn_no_encr.value,
        btrans_acct_nm: this.lv_ed_bfcer_dpstr_nm.value,
        cust_nm: this.lv_ed_edt_recp_nm.value,
        cust_no: this.lv_ed_bfcer_cust_no.value
      }

      FS000024.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportRP828200_Info'
      }
      // this.lv_formList.push(FS000024)
      formList.push(FS000024)

      FSInfoUtil.commReportInfo(this, formList, function() {}, false)
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      // let formList = []

      // // 커버발행 파라미터 저장
      // this.$bizUtil.fsUtils.saveCvrPblParam(this, {bfcer_cust_no: this.lv_cust_no})

      // let FS000024 = {formId: 'FS000024', isMulti: true} // // as-is : RCO000101 , FS000024_개인(신용)정보 등의 처리 동의 및 출금이체 신청서
      // let busyDate = FSCommUtil.gfn_strToday()

      // let searchData = {
      //    btrans_bank_nm : this.lv_ed_bfcer_bnk_nm.value
      //   ,btrans_acct_no : this.lv_ed_bfcer_bnk_accn_no_encr.value
      //   ,btrans_acct_nm : this.lv_ed_bfcer_dpstr_nm.value
      //   ,cust_nm        : this.lv_ed_edt_recp_nm.value
      //   ,cust_no        : this.lv_ed_bfcer_cust_no.value
      // }

      // FS000024.params = {
      //   reportParam: JSON.stringify(searchData),
      //   reportUrl: 'reportRP828200_Info',
      // }
      // formList.push(FS000024)

      // FSInfoUtil.commReportInfo(this, this.lv_formList, function() {}, false)
    },

    /******************************************************************************
     * Function명 : fn_AccnNoMask
     * 설명       : 계좌번호 마스킹
     ******************************************************************************/
    fn_AccnNoMask(accn_no) {
      if (accn_no.length >= 9) {
        // 연계계좌번호 9자리 이상인 경우
        accn_no = accn_no.substr(0, 3) + '*****' + accn_no.substr(8)
      }
      if (accn_no.length < 9) {
        // 연계계좌번호 9자리 미만인 경우
        accn_no = accn_no.substr(0, 3) + '*'.repeat(accn_no.length - 3)
      }
      return accn_no
    }
  }
}
</script>
<style scoped></style>
