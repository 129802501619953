/*
 * 업무구분: 책임자승인요청
 * 화 면 명: MSPFS320P(SY999801P.xfdl)
 * 화면설명: 책임자승인요청 POPUP
 * 작 성 일: 2023.08.13
 * 작 성 자: 유동훈
 */
<template>
  <mo-modal class="fts-modal large" ref="modal1" title="책임자승인요청">
    <div class="wrap-modalcontents">
      <div class="wrap-table-title row">
        <h2 class="table-title"> 승인자선택 </h2>
      </div>
      <div class="wrap-table mt10">
        <table class="table col-type th-row-gray text-center">
          <thead>
            <tr>
              <th class="w40"> 선택 </th>
              <th> 결재자명 </th>
              <th> 직위 </th>
              <th> 소속기관 </th>
            </tr>
          </thead>
          <tbody>
            <!-- <template v-for="(row, idx) in lv_apprvList" :class="{'checked': chkApprvList == String(idx)}" > -->
            <template v-for="(row, idx) in ds_unsol_staf_list" >
              <tr :key="idx.toString()">
                <td>
                  <mo-radio v-model="selectRow" :value="idx.toString()"></mo-radio>
                </td>
                <td>
                  {{row.staf_nm}}
                </td>
                <td>
                  {{row.auth_grp_cd_nm}}
                </td>
                <td>
                  {{row.fof_nm}}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="wrap-table-title row justify-between">
        <h2 class="table-title"> 승인요청메시지 </h2>
      </div>
      <div class="wrap-input">
        <mo-text-area placeholder="입력하세요." v-model="txtMsg" class="w100p" />
      </div>

      <div class="wrap-result row mt16 mb-5">
        <div class="left w50p">
          <p class="triangle">승인요청번호 <span> {{ ds_unsol_info.aprv_req_sno }} </span> </p>
        </div>
        <div class="left w50p">
          <p class="triangle text-result">승인결과 <span> {{ txtAlert }} </span> </p>
        </div>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point   size="large" @click="fn_close">닫기</mo-button>
        <mo-button primary size="large" :disabled="btnDisabled.req    == false" @click="fn_reqAdmission"      >승인요청</mo-button>
        <mo-button primary size="large" :disabled="btnDisabled.result == false" @click="fn_reqAdmissionResult">결과확인</mo-button>
      </div>
    </template>
    
    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역                                                            *
 * //parent set                                                                    *
 * msp-fs-320p':MSPFS320P                                                          *
 * <msp-ts-320p                                                                    *
 *   ref="popup320"                                                                *
 *   :popup320Obj="pPopup320Obj"                                                   *
 *   @ts-alert-popup="fn_AlertPopup"                                               *
 *   @ts-popup320-callback="fn_Popup320Back"                                       *
 * ></msp-ts-320p>                                                                 *
 ***********************************************************************************/
import FSCommUtil    from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSAlertPopup  from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
import FSInfoUtil    from '~/src/ui/fs/comm/FSInfoUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역                                                           *
   ***********************************************************************************/
  name: "MSPFS320P",
  screenId: "MSPFS320P",
  components: {
    'fs-alert-popup' : FSAlertPopup,
  },
  props: {
    popupObj: {type:Object, default: null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역                                                         *
   ***********************************************************************************/
  created() {
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS320P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역                                                                 *
   ***********************************************************************************/
  data() {
    return {

      eaiCommObj         : FSCommUtil.gfn_eaiCommObj(),

      successYN           : false,
      resultConfirmYn     : false,
      txtMsg              : '',
      txtAlert            : '',
      arm_alt_sno         : "",
      selectRow           : "",

      btnDisabled         : {
                              req    : true,
                              result : true,
                            },

      pAlertPopupObj      : {},

      ds_return_data      : {},
      ds_user_auth_grp_cd : [],  // 사용자 
      ds_unsol_staf_list  : [],  // 사용자 권한그룹
      ds_unsol_info       : {},  // 승인자 정보

      ds_unsol_result     : {}
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.modal1},
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    popupObj() {

    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /***************************************************************************************
    *	Function		: form_onload
    *	Parameter		: Form, LoadEventInfo
    *	Return			: 
    *	Desc		    : 화면 시작시 호출되는 함수
    *****************************************************************************************/
    fn_Open() {
      this.$nextTick(() => {        
        this.fn_init()
        this.modal1.open()
      })
    },

    /***************************************************************************************
    *	Function		: fn_getUserGrpCdList
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 사용자 권한그룹 코드를 조회
    *****************************************************************************************/
    fn_init() {
      this.selectRow = ""
      this.successYN = false
      this.fn_processReceiveData()
//      this.fn_getUserGrpCdList()
    },

    /***************************************************************************************
    *	Function		: fn_processReceiveData
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 입력 Parameter 정의
    *****************************************************************************************/
    fn_processReceiveData() {
      console.log("책임자 승인 요청 데이터 : ", this.popupObj)
      
      this.ds_unsol_info.adm_proc_gb    = this.popupObj.adm_proc_gb     // 승인처리구분 : 0 - 승인요청 1 - 승인응답
      this.ds_unsol_info.trt_ymd        = this.popupObj.trt_ymd         // 처리일
      this.ds_unsol_info.bfcer_fof_cd   = this.popupObj.bfcer_fof_cd    // 처리지점
      this.ds_unsol_info.bfcer_fof_nm   = this.popupObj.bfcer_fof_nm    // 처리지점명
      this.ds_unsol_info.aprv_req_sno   = this.popupObj.aprv_req_sno    // 승인일련번호
      this.ds_unsol_info.req_tr_code    = this.popupObj.req_tr_code     // 처리요청 tr_code
      this.ds_unsol_info.req_tr_code_nm = this.popupObj.req_tr_code_nm  // 처리화면명
      this.ds_unsol_info.rqstr_eno      = this.popupObj.rqstr_eno       // 요청사원번호
      this.ds_unsol_info.rqstr_eno_nm   = this.popupObj.rqstr_eno_nm    // 요청사원명
      this.ds_unsol_info.message        = this.popupObj.message         // 메시지
      this.ds_unsol_info.fileSeq        = this.popupObj.fileSeq         // 캡쳐 파일일련번호
      this.ds_unsol_info.orgFileNm      = this.popupObj.orgFileNm       // 캡쳐 원본파일명
      this.ds_unsol_info.fileChk        = this.popupObj.fileChk         // 캡쳐 파일체크

      this.ds_unsol_info.gv_adm_seq_no  = this.popupObj.gv_adm_seq_no   // 승인일련번호
      this.ds_unsol_info.gv_adm_eno     = this.popupObj.gv_adm_eno      // 승인사원번호
      this.ds_unsol_info.adm_eno        = this.popupObj.adm_eno         // 승인자 리스트

      this.txtMsg = this.ds_unsol_info.message

      console.log("책임자 승인 데이터 : ", this.ds_unsol_info)

      // this.ds_unsol_info.adm_eno = "100.21.104.21,D20604,김익환,수익증권P,영업점책임자#100.21.104.21,D20604,김익환,수익증권P,영업점책임자"
      // this.ds_unsol_info.aprv_req_sno   = 30    // 승인일련번호


      /* 승인자  목록 */
      if ( FSCommUtil.gfn_isNull(this.ds_unsol_info.adm_eno) ) {
        return
      }
      this.ds_unsol_staf_list = []
      let adm_eno_list = this.ds_unsol_info.adm_eno.split("#")
      for ( let i = 0; i < adm_eno_list.length; i++ ) {
        let adminfo_split = adm_eno_list[i].split(",");
        let adminfo = {}
        
        let j = 0
        adminfo.chk_yn         = ""                    // 책임자 선택여부
        adminfo.user_ip        = adminfo_split[j++]    // 책임자 IP
        adminfo.staf_eno       = adminfo_split[j++]    // 책임자 사원번호
        adminfo.staf_nm        = adminfo_split[j++]    // 책임자 명
        adminfo.fof_nm         = adminfo_split[j++]    // 책임자 지점명
        adminfo.auth_grp_cd_nm = adminfo_split[j++]    // 책임자 그룹명
        this.ds_unsol_staf_list.push(adminfo)
      }
    },

    /***************************************************************************************
    *	Function		: fn_getUserGrpCdList
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 사용자 권한그룹 코드를 조회
    *****************************************************************************************/
    fn_getUserGrpCdList() {

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570257_S'   // selectSY000000_UserGrpCdList
      this.eaiCommObj.params = {}

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570257, this.fn_error)
    },
    
    /***************************************************************************************
    *	Function		: fn_callBack_F10570257
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 사용자 권한그룹 코드를 조회
    *****************************************************************************************/
    fn_callBack_F10570257(pResultData) {
      console.log('fn_callBack_F10570257.....', pResultData)

      let t_data = pResultData.data
      this.ds_user_auth_grp_cd = t_data.outUserGrpCdList
    },

    /***************************************************************************************
    *	Function		: fn_reqAdmission
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 책임자 승인요청
    *****************************************************************************************/
    fn_reqAdmission() {

      let ds_admission_i_vo = {}
      ds_admission_i_vo.proc_gb      = "SA"
      ds_admission_i_vo.bfcer_fof_cd = this.ds_unsol_info.bfcer_fof_cd	// 처리점
      ds_admission_i_vo.bfcer_fof_nm = this.ds_unsol_info.bfcer_fof_nm	// 처리점 명
      ds_admission_i_vo.trt_ymd      = this.ds_unsol_info.trt_ymd     	// 처리일	this.gfn_userinfo("busyDate")
      ds_admission_i_vo.aprv_req_sno = this.ds_unsol_info.aprv_req_sno	// 승인일련번호
      ds_admission_i_vo.adm_eno      = this.ds_unsol_info.gv_adm_eno  	// 책임자

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570286_S'   // select_ADMState
      this.eaiCommObj.params = ds_admission_i_vo

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570286, this.fn_error)
    },
    
    /***************************************************************************************
    *	Function		: fn_callBack_F10570286
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 책임자 승인요청
    *****************************************************************************************/
    fn_callBack_F10570286(pResultData) {
      console.log('fn_callBack_F10570286.....', pResultData)

      let t_data = pResultData.data
      this.ds_unsol_result = t_data.tfoa01400_O_VO

      if ( this.ds_unsol_result.length <= 0 ) {
        this.resultConfirmYn    = true
        this.btnDisabled.req    = false
        this.btnDisabled.result = false
        this.fn_AlertPopup(0,{content : "책임자 승인 요청이 없습니다. 업무를 다시 진행해 주시기 바랍니다.", confirm: true, single:true}) 
        this.txtAlert = "책임자 승인 요청이 없습니다. 업무를 다시 진행해 주시기 바랍니다."
        return
      }

  
      let adm_state = this.ds_unsol_result[0].bfcer_aprv_stat_cd	// 승인상태
      let adm_eno   = this.ds_unsol_result[0].req_aprvr_eno				// 승인자 사번
      
      if ( adm_state == "15" ) {// 승인 상태 : 승인 완료
        this.successYN          = true
        this.resultConfirmYn    = true
        this.btnDisabled.req    = false
        this.btnDisabled.result = false
        this.btnDisabled.result = false
        let t_popupObj = {content : "책임자 승인이 완료되었습니다. '닫기' 버튼을 눌러주세요.", confirm: true, cancel: false}
        this.fn_AlertPopup(0, t_popupObj) 
        this.txtAlert = "책임자 승인이 완료되었습니다. '닫기' 버튼을 눌러주세요."
        this.ds_return_data.gv_adm_eno = adm_eno;
      } else if ( adm_state == "11" ) {		//승인 상태 : 요청 중
        this.successYN          = false
        this.btnDisabled.req    = true
        this.btnDisabled.result = true
        
        // //승인상태가 '요청 중' 일때 만 데이터 Send
        // let loopCnt = this.ds_unsol_staf_list.length
        // let findIdx = -1;

        // console.log(this.ds_unsol_staf_list)
        // for ( let i = 0; i < loopCnt; i++ ) {
        // 	if ( !FSCommUtil.gfn_isNull(this.ds_unsol_staf_list[i].chk_yn) ) {
        // 		findIdx = i;
        // 		this.ds_return_data.gv_adm_eno = this.ds_unsol_staf_list[i].staf_eno
        // 		break;
        // 	} 
        // } // end for
        
        if ( FSCommUtil.gfn_isNull(this.selectRow) ) {
          this.fn_AlertPopup(0,{content : "책임자를 선택해주십시요.", confirm: true, single:true}) 
          this.txtAlert = "책임자를 선택해주십시요."
          this.btnConfirmDisabled = true
          return;
        }
        
        //폴링데이터를 적재 요청
        this.ds_return_data.gv_adm_eno = this.ds_unsol_staf_list[Number(this.selectRow)].staf_eno
        console.log("this.ds_return_data.gv_adm_eno : ", this.ds_return_data.gv_adm_eno)
        this.fn_reqAdmissionPoll();
      }
    },

    /***************************************************************************************
    *	Function		: fn_reqAdmissionPoll
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 책임자승인 Polling 데이터를 적재
    *****************************************************************************************/
    fn_reqAdmissionPoll() {

      let ds_admission_i_vo = {}
      ds_admission_i_vo.proc_gb        = "SA"
      ds_admission_i_vo.bfcer_fof_cd   = this.ds_unsol_info.bfcer_fof_cd		// 처리점
      ds_admission_i_vo.bfcer_fof_nm   = this.ds_unsol_info.bfcer_fof_nm		// 처리점 명
      ds_admission_i_vo.trt_ymd        = this.ds_unsol_info.trt_ymd     		// 처리일

      ds_admission_i_vo.adm_proc_gb    = this.ds_unsol_info.adm_proc_gb 		// 승인요청구분
      ds_admission_i_vo.aprv_req_sno   = this.ds_unsol_info.aprv_req_sno		// 승인일련번호
      ds_admission_i_vo.adm_eno        = this.ds_return_data.gv_adm_eno     // 책임자

      ds_admission_i_vo.req_tr_code    = this.ds_unsol_info.req_tr_code 		// tr_code
      ds_admission_i_vo.req_tr_code_nm = this.ds_unsol_info.req_tr_code_nm	// req_tr_code_nm
      ds_admission_i_vo.req_staf_eno   = this.ds_unsol_info.rqstr_eno     	// req_staf_eno
      ds_admission_i_vo.req_staf_nm    = this.ds_unsol_info.rqstr_eno_nm  	// req_staf_nm
      ds_admission_i_vo.message        = this.txt_Msg         			        // message

      ds_admission_i_vo.fileseq        = this.ds_unsol_info.fileSeq  				// fileSeq
      ds_admission_i_vo.orgfilenm      = this.ds_unsol_info.orgFileNm				// orgFileNm
      ds_admission_i_vo.filechk        = this.ds_unsol_info.fileChk  				// fileChk
      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570287_S'   // select_ADMState
      this.eaiCommObj.params = ds_admission_i_vo

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570287, this.fn_error)
    },
    
    /***************************************************************************************
    *	Function		: fn_callBack_F10570287
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 책임자승인 Polling 데이터를 적재
    *****************************************************************************************/
    fn_callBack_F10570287(pResultData) {
      console.log('fn_callBack_F10570287.....', pResultData)

      let t_data = pResultData.data
      this.ds_admission_o_vo = t_data
			this.arm_alt_sno = this.ds_admission_o_vo.arm_alt_sno
			this.fn_AlertPopup(0,{content : "책임자 승인을 요청했습니다. 승인결과를 조회하세요.", confirm: true, cancel:false,}) 
      this.txtAlert = "책임자 승인을 요청했습니다. 승인결과를 조회하세요."
    },


    /***************************************************************************************
    *	Function		: fn_reqAdmissionPoll
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 승인결과조회
    *****************************************************************************************/
    fn_reqAdmissionResult() {
      // this.btnDisabled.req    = false
      // this.btnDisabled.result = false

      let ds_admission_i_vo = {}

      ds_admission_i_vo.proc_gb       = "SA"
      ds_admission_i_vo.bfcer_fof_cd  =  this.ds_unsol_info.bfcer_fof_cd	// 처리점
      ds_admission_i_vo.bfcer_fof_nm  =  this.ds_unsol_info.bfcer_fof_nm	// 처리점 명
      ds_admission_i_vo.trt_ymd       =  this.ds_unsol_info.trt_ymd     	// 처리일
      ds_admission_i_vo.aprv_req_sno  =  this.ds_unsol_info.aprv_req_sno	// 승인일련번호
      ds_admission_i_vo.adm_eno       =  this.ds_unsol_info.gv_adm_eno    // 책임자
      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570286_S'   // select_ADMStateResult
      this.eaiCommObj.params = ds_admission_i_vo

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570286_Result, this.fn_error)
    },
    
    /***************************************************************************************
    *	Function		: fn_callBack_F10570286_Result
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 승인결과조회
    *****************************************************************************************/
    fn_callBack_F10570286_Result(pResultData) {
      console.log('fn_callBack_F10570286_Result.....', pResultData)

      let t_data = pResultData.data
      this.ds_unsol_result = t_data.tfoa01400_O_VO

			if ( this.ds_unsol_result.length <= 0 ) {
        this.resultConfirmYn    = true
        this.btnDisabled.req    = false
        this.btnDisabled.result = false
        this.fn_AlertPopup(0,{content : "책임자 승인 요청이 없습니다. 업무를 다시 진행해 주시기 바랍니다.", confirm: true, single:true}) 
        this.txtAlert = "책임자 승인 요청이 없습니다. 업무를 다시 진행해 주시기 바랍니다."
        return
      }


      let adm_state = this.ds_unsol_result[0].bfcer_aprv_stat_cd		// 승인상태
      let adm_eno   = this.ds_unsol_result[0].req_aprvr_eno				// 승인자 사번

      if ( adm_state == "15") {
        this.successYN          = true
        this.resultConfirmYn    = true
        this.btnDisabled.req    = false
        this.btnDisabled.result = false
        let t_popupObj = {content : "책임자 승인이 완료되었습니다. '닫기' 버튼을 눌러주세요.", confirm: true, single:true, cancel: false}
        this.fn_AlertPopup(0,t_popupObj)
        this.txtAlert = "책임자 승인이 완료되었습니다. '닫기' 버튼을 눌러주세요."
        this.ds_return_data.gv_adm_eno = adm_eno;
      } else if ( adm_state == "11" ) {
        this.successYN          = false
        this.resultConfirmYn    = false
        this.btnDisabled.req    = true
        this.btnDisabled.result = true
        this.fn_AlertPopup(0,{content : "책임자 승인이 완료되지 않았습니다! 잠시 후 다시 조회해 주세요.", confirm: true, single:true}) 
        this.txtAlert = "책임자 승인이 완료되지 않았습니다! 잠시 후 다시 조회해 주세요."
      } else if( adm_state == "13" ) {
        this.successYN          = false
        this.resultConfirmYn    = true
        this.btnDisabled.req    = false
        this.btnDisabled.result = false
        this.fn_AlertPopup(0,{content : "책임자 승인이 거부되었습니다. '닫기' 버튼을 눌러주세요.", confirm: true, single:true}) 
        this.txtAlert = "책임자 승인이 거부되었습니다. '닫기' 버튼을 눌러주세요."
      }
    },

    /***************************************************************************************
    *	Function		: fn_close
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 승인결과 리터 및 화면 닫기
    *****************************************************************************************/
    fn_close() {

      if ( this.resultConfirmYn == false ) {
        let tmpContentTitle = "" 
        let tmpContent = [] 
        tmpContent.push('[승인결과조회]를 클릭하여 승인결과를 확인하십시오.')
        tmpContent.push('승인미완료 상태로 닫으면 승인요청취소합니다.')
        tmpContent.push('창을 닫으시겠습니까?')

        let t_popupObj = {
          confirm      : true,
          confirmFunc  : this.fn_cancelApprove,
          content      : tmpContent,
          contentTitle : tmpContentTitle,
        }
        this.fn_AlertPopup(0, t_popupObj)
        return
      }

      if ( this.successYN == true ) {
        /* 승인일련번호 */
        this.ds_return_data.gv_adm_seq_no = this.ds_unsol_info.aprv_req_sno
      } else {
        /* 알림등록 되어 있을경우 취소 요청함. */
        if ( !FSCommUtil.gfn_isNull(this.arm_alt_sno) ) {
          
          /* 알림 등록 취소  */
          let ds_admission_i_vo = {}
          ds_admission_i_vo.arm_alt_sno = this.arm_alt_sno
          
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570285_S'   // update_cancArmAdmission
          this.eaiCommObj.params = ds_admission_i_vo

          FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10570285, this.fn_error)
        }	
      }

      this.fn_returnData()
    },

    /***************************************************************************************
    *	Function		: fn_callBack_F10570285
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 알림 취소
    *****************************************************************************************/
    fn_callBack_F10570285(pResultData) {
      console.log('fn_callBack_F10570285.....', pResultData)
    },

    /***************************************************************************************
    *	Function		: fn_cancelApprove
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 취소
    *****************************************************************************************/
    fn_cancelApprove() {
      this.ds_return_data.gv_adm_seq_no = ""
      this.ds_return_data.gv_adm_eno    = ""
      this.fn_returnData()
    },

    /***************************************************************************************
    *	Function		: fn_returnData
    *	Parameter		: 
    *	Return			: 
    *	Desc			  : 결과전달
    *****************************************************************************************/
    fn_returnData() {
      this.ds_return_data.successYN   = this.successYN ? "Y" : "N"
      this.ds_return_data.req_tr_code = FSCommUtil.gfn_substr(this.ds_unsol_info.req_tr_code, 2, 4)


      console.log("this.ds_return_data : ", this.ds_return_data)

      this.$emit('fs-popup-callback', this.ds_return_data)
      this.modal1.close()
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
  }
};
</script>
<style scoped>
</style>