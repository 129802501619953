/*
 * 업무구분: 영업
 * 화 면 명: MSPFS316M
 * 화면설명: 리얼타임입금관리(RP828200M.xdlf:8282)
 * 작 성 일: 2023.01.16
 * 작 성 자: 
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="실시간입금관리" :topButton="true">

    <fs-header ref="fsHeader" :propObj="pHeaderObj" ></fs-header>

    <!-- .fts-main -->
    <main class="fts-main">
     <template>
      <ur-box-container direction="column" alignV="start">
        <mo-tab-box class="fts-tab" :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" >
          <mo-tab-content :idx="1" label="실시간계좌등록/조회" >
            <tabMSPFS316D v-show="slctTabIdx === 1" :slctTabIdx="slctTabIdx" />
          </mo-tab-content>
          <mo-tab-content :idx="2" label="실시간입금처리" >
            <tabMSPFS317D v-show="slctTabIdx === 2" :slctTabIdx="slctTabIdx" />
          </mo-tab-content>      
        </mo-tab-box>
      </ur-box-container>
    </template>
    </main>
    <!-- //.fts-main -->

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/

import tabMSPFS316D from '@/ui/fs/MSPFS316D'
import tabMSPFS317D from '@/ui/fs/MSPFS317D'
import FSHeader from '~/src/ui/fs/comm/FSHeader'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS316M",
  screenId: "MSPFS316M",
  components: {
    'tabMSPFS316D' : tabMSPFS316D ,
    'tabMSPFS317D' : tabMSPFS317D ,
    'fs-header':FSHeader,  
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let lv_vm = this
    window.fdpbridge.exec('RecordStatusPlugin', {}, (result) => {
      try {
        if (result.data === 'Playing') {
          lv_vm.fn_trace('info', '녹취 진행중...')
        } else if (result.data === 'Stop'){
          lv_vm.fn_trace('error', '녹취 중단 !!!', result.data)
          lv_vm.fn_AlertMsg('error', '녹취가 중단되었습니다.\n중단하시겠습니까?', '아니오|예')
        } 
      } catch (error) {
        lv_vm.fn_trace('error', '녹취 진행 중 오류 발생')
      }
    }, () => {
      lv_vm.fn_trace('error', '녹취 진행 중 오류 발생')
    })

  },
  mounted() {
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.fn_TabChangeHandler(this.defaultIdxs)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // Tab
      defaultIdxs : 2,
      slctTabIdx : 2 ,

      // Accordion Toggle
      isActive: false,

      pHeaderObj:{title:'실시간입금관리', step:''},

      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    // Tab
    fn_TabChangeHandler(idx){      
      this.slctTabIdx = idx
      // console.log("this.slctTabIdx = "+ this.slctTabIdx)
    },
  }
};
</script>
<style scoped>
</style>