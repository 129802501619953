/* * 업무구분: 영업 * 화 면 명: MSPFS317P * 화면설명: 이체내역확인 * 작 성 일: 2023.05.15 * 작 성 자: 이성룡 */
<template>
  <ur-page-container class="fts" :show-title="false" title="이체내역확인" :topButton="true">
    <fs-alert-popup ref="alertPopup" :popupObj="pAlert"></fs-alert-popup>
    <mo-modal class="fts-modal medium" ref="modal" title="이체내역확인">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table">
            <table class="table col-type">
              <tbody>
                <tr>
                  <th class="w29p">
                    <span>출금계좌</span>
                  </th>
                  <td>{{ stc_bankNm }}</td>
                </tr>
                <tr>
                  <th>
                    <span>예금주명</span>
                  </th>
                  <td>{{ stc_acctNm }}</td>
                </tr>
                <tr>
                  <th>
                    <span>입금계좌</span>
                  </th>
                  <td>{{ stc_buyAcctNo }}</td>
                </tr>
                <tr>
                  <th>
                    <span>이체금액</span>
                  </th>
                  <td class="aR">{{ mae_paym_amt }} 원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="close">닫기</mo-button>
          <mo-button primary :disabled="btn_disableTransfer" @click="executeTransfer" size="large">{{ btn_text }}</mo-button>
        </div>
      </template>
    </mo-modal>

    <msp-fs-320p ref="popup320" :popupObj="pPopup320Obj" @fs-popup-callback="callback320P" />
  </ur-page-container>
</template>
<script>
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'
import MSPFS320P from '~/src/ui/fs/MSPFS320P'

export default {
  name: 'MSPFS317P',
  screenId: 'MSPFS317P',

  components: {
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-320p': MSPFS320P
  },

  props: {
    popupObj: { type: Object, default: null }
  },

  created() {
    this.fn_SetCommCode()
    this.ENV = process.env.NODE_ENV
  },

  mounted() {
    this.$bizUtil.insSrnLog('MSPFS317P')
  },

  data() {
    return {
      stc_bankNm: '',
      stc_acctNm: '',
      mae_paym_amt: '',
      stc_buyAcctNo: '',
      transfer_status: '',
      intrn_pym_id: '',
      err_code: '',
      btn_text: '',
      lv_commCode: {},
      pResult: {},
      pAlert: {},
      rp828200_O_07VO: [],
      btn_disableTransfer: false,
      eai: FSCommUtil.gfn_eaiCommObj(),
      ENV: '',
      pPopup320Obj: {},
      gv_adm_seq_no: 0,
      gv_adm_eno: '',
      report: {}
    }
  },

  computed: {
    modal() {
      return this.$refs.modal
    }
  },

  watch: {},

  methods: {
    fn_Open() {
      setTimeout(() => this.fn_Init(), 100)
      this.modal.open()
    },

    fn_Close() {
      this.modal.close()
    },

    fn_Init() {
      console.log('\t Parameter : ', JSON.stringify(this.popupObj))

      this.stc_bankNm = this.popupObj.bfcer_bnk_nm + ' ' + this.popupObj.bfcer_bnk_accn_no_encr
      this.stc_acctNm = this.popupObj.bfcer_dpstr_nm
      this.mae_paym_amt = this.$commonUtil.numberWithCommas(this.popupObj.rtt_rctm_req_amt)
      this.stc_buyAcctNo =
        this.popupObj.bfcer_accn_no.substr(0, 7) + '-' + this.popupObj.bfcer_accn_no.substr(7, 3) + '-' + this.popupObj.bfcer_accn_no.substr(10, 3)
      this.transfer_status = '1'
      this.btn_text = '이체실행'
      this.btn_disableTransfer = false
      this.rp828200_O_07VO = []
      this.intrn_pym_id = ''
      this.err_code = ''
      this.gv_adm_seq_no = 0
      this.gv_adm_eno = ''
    },

    fn_SetCommCode() {
      // F10570266 공통코드조회(AS-IS:selectComboList)
      let param = [{ cId: '610', dayChkYn: 'N' }]

      FSCodeUtil.commCodeList(this, param, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult
    },

    executeTransfer() {
      console.log('Transfer Status : [' + this.transfer_status + ']')
      if (this.transfer_status == '1') {
        this.requestRT()
      } else if (this.transfer_status == '4') {
        this.resultRT()
      }
    },

    requestRT() {
      // F10570231 RT계좌신규입금요청(AS-IS:insertRP828200_performDpsTrsRgstMain)
      this.eai.lv_vm = this
      this.eai.auth = 'S'
      this.eai.commHeaderVO.eaiId = 'C391_F10570231_S'
      this.eai.params = {
        proc_gb: 'TC',
        tr_code: 'RP8282',
        bfcer_cust_no: this.popupObj.bfcer_cust_no,
        bfcer_rtt_accn_sno: this.popupObj.bfcer_rtt_accn_sno,
        bfcer_bnk_cd: this.popupObj.bfcer_bnk_cd,
        bfcer_bnk_nm: this.popupObj.bfcer_bnk_nm,
        bfcer_bnk_accn_no_encr: this.popupObj.bfcer_bnk_accn_no_encr,
        bfcer_dpstr_nm: this.popupObj.bfcer_dpstr_nm,
        dpstr_rlnm_cnf_no_encr: this.popupObj.dpstr_rlnm_cnf_no_encr,
        tel_use_yn: this.popupObj.tel_use_yn,
        rtt_rctm_req_amt: this.popupObj.rtt_rctm_req_amt,
        sms_dspch_yn: this.popupObj.sms_dspch_yn,
        rtt_rctm_sc_cd: this.popupObj.rtt_rctm_sc_cd,
        bfcer_accn_no: this.popupObj.bfcer_accn_no,
        payr_no: this.popupObj.payr_no,
        bfcer_core_accn_id: this.popupObj.bfcer_core_accn_id,
        sup_id: this.gv_adm_eno, // 승인자
        aprv_sno: this.gv_adm_seq_no, // 승인번호
        secure_passv: this.popupObj.secure_password,
        secure_encsymm: this.popupObj.secure_encsymm,
        ars_pswn_yn: '',
        dfra_bfcer_accn_no: '' // VO의 default String(" ")을 무시해야 함
      }

      console.log('F10570231 Parameter : ', JSON.stringify(this.eai.params))
      FSServiceUtil.invokeInDirect(this.eai, this.callbackRequestRT)
    },

    callbackRequestRT(res) {
      console.log('입금요청 Result : ' + JSON.stringify(res.data))
      console.log('\t error_msg : [' + res.data.error_msg + ']')

      if (res.data.error_msg == '050013') {
        let paramData = {
          adm_proc_gb: res.data.adm_proc_gb, // 승인처리구분 : 0 - 승인요청 1 - 승인응답
          trt_ymd: res.data.trt_ymd, // 처리일
          bfcer_fof_cd: res.data.bfcer_fof_cd, // 처리지점
          bfcer_fof_nm: res.data.bfcer_fof_nm, // 처리지점명
          aprv_req_sno: res.data.aprv_req_sno, // 승인일련번호
          req_tr_code: res.data.req_tr_code, // 처리요청 tr_code
          req_tr_code_nm: res.data.req_tr_code_nm, // 처리화면명
          rqstr_eno: res.data.rqstr_eno, // 요청사원번호
          rqstr_eno_nm: res.data.rqstr_eno_nm, // 요청사원명
          message: res.data.message, // 메시지
          fileSeq: res.data.fileSeq, // 캡쳐 파일일련번호
          orgFileNm: res.data.orgFileNm, // 캡쳐 원본파일명
          fileChk: res.data.fileChk, // 캡쳐 파일체크
          gv_adm_seq_no: this.gv_adm_seq_no, // 승인일련번호
          gv_adm_eno: this.gv_adm_eno, // 승인사원번호
          adm_eno: res.data.adm_eno // 승인자 리스트
        }

        console.log('책임자 승인 paramData : ', paramData)

        this.pPopup320Obj = paramData
        this.$refs.popup320.fn_Open()
        return
      }

      if (FSCommUtil.gfn_trim(res.data.error_msg) != '') {
        this.fn_AlertPopup(0, {
          content: FSMessageUtil.commMessage('')[res.data.error_msg]
        })
        return
      }

      this.rp828200_O_07VO = res.data.rp828200_O_07VO || []

      console.log('\t err_code : [' + res.data.rp828200_O_06VO.err_code + ']')
      if (res.data.rp828200_O_06VO.length > 0 && res.data.rp828200_O_06VO[0].err_code == '1') {
        this.errorHandling()
        return
      } else if (
        res.data.rp828200_O_06VO.length > 0 &&
        (res.data.rp828200_O_06VO[0].err_code == '2' || res.data.rp828200_O_06VO[0].err_code == '4')
      ) {
        this.errorHandling()
        this.btn_disableTransfer = true
        return
      } else {
        if (res.data.fossadmissionVO.length > 0 && res.data.fossadmissionVO[0].aprv_req_sno != 0) {
          console.log('@@@@@@@@@@@@ 승인자정보 @@@@@@@@@@@')
          console.log('승인자 : [' + this.gv_adm_eno + ']')
          console.log('승인번호 : [' + this.gv_adm_seq_no + ']')
          console.log(JSON.stringify(res.data.fossadmissionVO))

          let paramData = {
            adm_proc_gb: res.data.fossadmissionVO[0].adm_proc_gb, // 승인처리구분 : 0 - 승인요청 1 - 승인응답
            trt_ymd: res.data.fossadmissionVO[0].trt_ymd, // 처리일
            bfcer_fof_cd: res.data.fossadmissionVO[0].bfcer_fof_cd, // 처리지점
            bfcer_fof_nm: res.data.fossadmissionVO[0].bfcer_fof_nm, // 처리지점명
            aprv_req_sno: res.data.fossadmissionVO[0].aprv_req_sno, // 승인일련번호
            req_tr_code: res.data.fossadmissionVO[0].req_tr_code, // 처리요청 tr_code
            req_tr_code_nm: res.data.fossadmissionVO[0].req_tr_code_nm, // 처리화면명
            rqstr_eno: res.data.fossadmissionVO[0].rqstr_eno, // 요청사원번호
            rqstr_eno_nm: res.data.fossadmissionVO[0].rqstr_eno_nm, // 요청사원명
            message: res.data.fossadmissionVO[0].message, // 메시지
            fileSeq: res.data.fossadmissionVO[0].fileSeq, // 캡쳐 파일일련번호
            orgFileNm: res.data.fossadmissionVO[0].orgFileNm, // 캡쳐 원본파일명
            fileChk: res.data.fossadmissionVO[0].fileChk, // 캡쳐 파일체크
            gv_adm_seq_no: this.gv_adm_seq_no, // 승인일련번호
            gv_adm_eno: this.gv_adm_eno, // 승인사원번호
            adm_eno: res.data.fossadmissionVO[0].adm_eno // 승인자 리스트
          }

          console.log('책임자 승인 paramData : ', paramData)

          this.pPopup320Obj = paramData
          this.$refs.popup320.fn_Open()
        } else {
          this.transfer_status = '4'
          this.intrn_pym_id = res.data.rp828200_O_07VO.length == 0 ? '' : FSCommUtil.gfn_trim(res.data.rp828200_O_07VO[0].intrn_pym_id)
          this.err_code = res.data.rp828200_O_06VO.length == 0 ? '' : FSCommUtil.gfn_trim(res.data.rp828200_O_06VO[0].err_code)

          console.log('\t intrn_pym_id : [' + this.intrn_pym_id + ']')
          console.log('\t err_code : [' + this.err_code + ']')

          this.fn_AlertPopup(0, {
            cancel: false,
            confirm: true,
            confirmFunc: this.resultRT,
            content: '* 처리중 계속 확인바랍니다.'
          })
        }
      }
    },

    resultRT() {
      // F10570232 RT계좌입금결과확인(AS-IS:insertRP828200_performDpsRsltCfrmMain)
      if (this.err_code == '3' && this.intrn_pym_id == '') {
        this.fn_AlertPopup(0, {
          content: '전송오류 (이체 진행중이나, Payment ID가 존재하지 않습니다.)\n본사 확인후 진행해주세요.\n[#8283] RT입금처리이력 화면을 확인하세요'
        })
        this.btn_text = '본사확인'
        return
      }

      console.log('Invoke RT계좌입금결과확인 ...')
      this.eai.lv_vm = this
      this.eai.auth = 'S'
      this.eai.commHeaderVO.eaiId = 'C391_F10570232_S'
      this.eai.params = {
        proc_gb: 'TC',
        tr_code: 'RP8282',
        acct_id: this.popupObj.bfcer_cust_no,
        bfcer_rtt_accn_sno: this.popupObj.bfcer_rtt_accn_sno,
        bfcer_bnk_cd: this.popupObj.bfcer_bnk_cd,
        bfcer_bnk_nm: this.popupObj.bfcer_bnk_nm,
        bfcer_bnk_accn_no_encr: this.popupObj.bfcer_bnk_accn_no_encr,
        bfcer_dpstr_nm: this.popupObj.bfcer_dpstr_nm,
        dpstr_rlnm_cnf_no_encr: this.popupObj.dpstr_rlnm_cnf_no_encr,
        clear_reci_yn: this.popupObj.tel_use_yn,
        rtt_rctm_req_amt: this.popupObj.rtt_rctm_req_amt,
        sms_dspch_yn: this.popupObj.sms_dspch_yn,
        rtt_rctm_sc_cd: this.popupObj.rtt_rctm_sc_cd,
        dfra_bfcer_accn_no: this.popupObj.bfcer_accn_no, // 기간계에서 bfcer_accn_no로 매핑한다
        payr_no: this.popupObj.payr_no,
        apamt_dl_no: this.rp828200_O_07VO.length == 0 ? '' : this.rp828200_O_07VO[0].rtt_dl_sno,
        bfcer_rtt_accn_reg_cd: this.rp828200_O_07VO.length == 0 ? '' : this.rp828200_O_07VO[0].rt_trsf_brkdn_id,
        bfcer_core_accn_id: this.popupObj.bfcer_core_accn_id,
        apamt_accn_yn: this.rp828200_O_07VO.length == 0 ? '' : this.rp828200_O_07VO[0].clear_reci_yn,
        intrn_pym_id: this.intrn_pym_id,
        rtt_accn_reg_stat_cd: this.popupObj.secure_password,
        ars_pswn_yn: ''
      }

      console.log('F10570232 Parameter : ', JSON.stringify(this.eai.params))
      FSServiceUtil.invokeInDirect(this.eai, this.callbackResultRT)
    },

    callbackResultRT(res) {
      console.log('결과확인 Result : ' + JSON.stringify(res.data))
      console.log('\t error_msg : [' + res.data.error_msg + ']')

      if (FSCommUtil.gfn_trim(res.data.error_msg) != '') {
        this.fn_AlertPopup(0, {
          content: FSMessageUtil.commMessage('')[res.data.error_msg]
        })
        return
      }

      if (res.data.rp828200_O_06VO.err_code == '1' || res.data.rp828200_O_06VO.err_code == '2') {
        this.errorHandling()
        return
      } else {
        let rtt_dl_prg_stat_cd = FSCommUtil.gfn_trim(res.data.tfoc03182_O_VO[0].rtt_dl_prg_stat_cd)
        let bfcer_tcomp_resp_cd = FSCommUtil.gfn_trim(res.data.tfoc03182_O_VO[0].bfcer_tcomp_resp_cd)

        console.log('\t rtt_dl_prg_stat_cd : [' + rtt_dl_prg_stat_cd + ']')
        console.log('\t bfcer_tcomp_resp_cd : [' + bfcer_tcomp_resp_cd + ']')
        if (bfcer_tcomp_resp_cd == '') {
          if (rtt_dl_prg_stat_cd == '00') {
            this.transferSuccess(res)
          } else {
            this.transferFailure(res)
          }
        } else {
          this.transferSuccess(res)
        }
      }
    },

    transferSuccess(res) {
      console.log('이체 성공 후 ...')
      console.log('\t Result : ' + JSON.stringify(res.data))

      let rtt_dl_prg_stat_cd = FSCommUtil.gfn_trim(res.data.tfoc03182_O_VO[0].rtt_dl_prg_stat_cd)
      let bfcer_tcomp_resp_cd = FSCommUtil.gfn_trim(res.data.tfoc03182_O_VO[0].bfcer_tcomp_resp_cd)
      let bfcer_tcomp_resp_cd_nm = FSCommUtil.gfn_trim(res.data.tfoc03182_O_VO[0].bfcer_tcomp_resp_cd_nm)

      console.log('\t rtt_dl_prg_stat_cd : [' + rtt_dl_prg_stat_cd + ']')
      console.log('\t bfcer_tcomp_resp_cd : [' + bfcer_tcomp_resp_cd + ']')
      console.log('\t bfcer_tcomp_resp_cd_nm : [' + bfcer_tcomp_resp_cd_nm + ']')

      let o1 = this.lv_commCode.code610.filter((codeset) => codeset.value == 'RT_EMP_NO1')
      let o2 = this.lv_commCode.code610.filter((codeset) => codeset.value == 'RT_EMP_NO2')
      let str_telno = '-> 본사담당[' + o1 + ', ' + o2 + ']'

      console.log('\t o1 : ' + JSON.stringify(o1))
      console.log('\t o2 : ' + JSON.stringify(o2))

      if (rtt_dl_prg_stat_cd == '02') {
        this.transfer_status = '2' // 정상입금
        this.btn_text = '정상완료'
        this.btn_disableTransfer = true

        this.report = res.data
        if (res.data.tfoc03182_O_VO.length > 0) {
          this.report.bfcer_cust_no = res.data.tfoc03182_O_VO[0].bfcer_cust_no
        }

        this.fn_AlertPopup(9, {
          confirm: true,
          confirmFunc: this.fn_Report,
          confirmData: this.report,
          content: ['정상처리되었습니다.']
        })

        if (res.data.buy_REPORT.length > 0 && res.data.buy_REPORT[0].tm_bes_dl_yn == 'Y') {
          this.fn_AlertPopup(0, {
            content: 'LT(Late-Trading)거래입니다. 고객님께 안내 바랍니다.'
          })
        }
      } else if (rtt_dl_prg_stat_cd == '03') {
        this.transfer_status = '3'
        this.btn_text = '이체불능'
        this.btn_disableTransfer = true
        this.fn_AlertPopup(0, { content: '* 처리결과 이체불능입니다.' })
      } else if (rtt_dl_prg_stat_cd == '00') {
        this.transfer_status = '9'
        this.btn_text = '이체취소'
        this.btn_disableTransfer = true
        this.fn_AlertPopup(0, {
          content: '* 처리결과 이체요청실패로 취소되었습니다.'
        })
      } else {
        this.transfer_status = '4'
        this.btn_disableTransfer = false
        this.btn_text = '결과확인'
        this.fn_AlertPopup(0, {
          content: '* 이체진행중입니다. 결과를 다시 확인하십시오.'
        })
      }
    },

    async fn_Report() {
      console.log('전자문서 ...')

      let formList = []
      let FS000017 = { formId: 'FS000017' } // 실시간입금신청서
      let o = {
        rt_acct_seq_no: this.report.rt_DEOP_REPORT[0].rt_acct_seq_no,
        tel_use_yn: '',
        tr_code: 'RP8282',
        bfcer_cust_no: this.popupObj.bfcer_cust_no,
        // frm_issnc_ymd: this.report.buy_REPORT[0].recp_req_dt,
        bfcer_busn_trt_id: 'RP8282',
        rprt_frm_no: '0027'
      }

      console.log('\t 1. 전자서식 FS000017 (실시간입금신청서) Parameter : ' + JSON.stringify(o))

      FS000017.params = {
        reportParam: JSON.stringify(o),
        reportUrl: 'reportRP828200_Info_01'
      }

      formList.push(FS000017)
      // 2024.01.17 ASR240100575 매입/환매/전환신청서 나오게 수정
      // if ( this.report.buy_REPORT.length > 0 && this.report.buy_REPORT[0].tm_bes_dl_yn == 'Y' )
      if (this.report.buy_REPORT.length > 0) {
        console.log('매입/환매/전환신청서 add ...')
        let FS000014 = { formId: 'FS000014' } // 매입/환매/전환신청서

        o.issnc_hstr_sno = this.report.buy_REPORT[0].h_seq_no
        o.frm_issnc_ymd = this.report.buy_REPORT[0].recp_req_dt

        console.log('\t 2. 전자서식 FS000014 (매입/환매/전환신청서) Parameter : ' + JSON.stringify(o))

        FS000014.params = {
          reportParam: JSON.stringify(o),
          reportUrl: 'reportCO070500_Info'
        }

        formList.push(FS000014)
      }

      console.log('@@@@@ 전자서식 Parameter : ' + JSON.stringify(o))

      FSInfoUtil.commReportInfo(this, formList, null, true, 'MSPFS317P')
      //this.$emit('fs-popup-callback', 'MSPFS317P')
      //this.modal.close()
    },

    transferFailure(res) {
      console.log('이체 실패 후 ...')
      this.btn_text = '이체실행'
      this.btn_disableTransfer = false
      this.transfer_status = '1'
      this.fn_AlertPopup(0, {
        content: '[#8283] RT입금처리이력 화면을 확인하세요'
      })
    },

    callback320P(res) {
      console.log('책임자승인 Callback ...')
      console.log('\t RESULT : ', res)

      this.gv_adm_seq_no = res.gv_adm_seq_no
      this.gv_adm_eno = res.gv_adm_eno

      if (res.successYN == 'N') {
        this.fn_AlertPopup(0, { content: '책임자 승인요청이 취소되었습니다.' })
      } else {
        this.requestRT()
      }
    },

    errorHandling() {
      this.btn_text = '이체실행'
      this.btn_disableTransfer = false
      this.transfer_status = '1'
      this.fn_AlertPopup(0, {
        content: '[#8283] RT입금처리이력 화면을 확인하세요'
      })
    },

    close() {
      if (this.transfer_status != '1' && this.transfer_status != '0') {
        if (this.transfer_status == '4') {
          this.fn_AlertPopup(0, {
            cancel: false,
            confirm: true,
            confirmFunc: this.confirmY,
            closeFunc: this.closeY,
            content: '* 이체요청후 결과가 확인이 되지 않았습니다. 창을 닫으시겠습니까?\n\n>> 결과미확인건은 [#8283]에서 재처리 할 수 있습니다.'
          })
        } else {
          this.modal.close()
        }
      } else {
        this.modal.close()
      }
    },

    closeY() {
      this.modal.close()
    },

    confirmY() {
      this.$emit('fs-popup-callback', { closeOK: 'Y' })
      this.modal.close()
    },

    fn_AlertPopup(type, o) {
      if (!FSCommUtil.gfn_isNull(o)) {
        FSInfoUtil.initAlertPopObj(type, this.pAlert, o)
      }

      this.$refs.alertPopup.fn_Open()
    }
  }
}
</script>
<style scoped></style>
